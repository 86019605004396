import React from 'react';

import { FirmwareNode } from 'main/models';

interface Props {
  edges: Edge<FirmwareNode>[];
  listType: 'firmware' | 'driver';
}

export const FirmwareList: React.FC<Props> = ({ listType, edges }) => {
  const isEdgesActive = edges && edges.length;
  return (
    <>
      {isEdgesActive && (
        <div className="page__table">
          {edges
            .sort(
              (a, b) =>
                new Date(a.node.date || '').getTime() -
                new Date(b.node.date || '').getTime(),
            )
            .reverse()
            .map((data, index) => (
              <div key={index} className="page__table__item">
                <div className="page__table__item__heading">
                  <p className="t-eta">{data.node.name[0]?.text}</p>
                  <p className="t-zeta o-40">{data.node.version[0]?.text}</p>
                </div>
                <div className="page__table__item__action">
                  <a
                    href={data.node[listType].url}
                    className="btn btn--sml btn--nopadding"
                  >
                    Download
                  </a>
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  );
};
