import { motion } from 'framer-motion';
import React from 'react';
import InView from 'react-intersection-observer';

export const ProductExperience: React.FC = () => (
  <InView triggerOnce>
    {({ ref, inView }) => (
      <section className="fpvonerace__visual--experience fpvonerace__visual__experience--secondary">
        <motion.img
          initial={{ opacity: 0 }}
          animate={{
            opacity: inView ? 1 : 0,
          }}
          transition={{
            ease: 'easeOut',
            delay: 0.4,
            duration: 0.6,
          }}
          src={require('main/assets/images/fpvonerace/ORQA_MKII_Experience.webp')}
          alt="FPV PILOT visual experience"
          className="fpvonerace__specifications__image--absolute"
        />
        <div className="fpvrace__list--wrapper">
          <div className="fpvonerace__list__content--secondary">
            <motion.h3
              initial={{ opacity: 0, y: '20px' }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : '20px',
              }}
              transition={{
                ease: 'easeOut',
                duration: 0.4,
              }}
              className="fpvonepilot__visual__title--secondary t-upper"
            >
              experience
            </motion.h3>
            <motion.h2
              ref={ref}
              initial={{ opacity: 0, y: '30px' }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : '30px',
              }}
              transition={{
                ease: 'easeOut',
                delay: 0.2,
                duration: 0.4,
              }}
              className="fpvonerace__specifications--title fpvonerace__experience--title t-upper"
            >
              A step towards the future.
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: '50px' }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : '50px',
              }}
              transition={{
                ease: 'easeOut',
                delay: 0.4,
                duration: 0.4,
              }}
              className="fpvpilot__splitsection--description fpvrace__experience--description"
            >
              For all the like-minded enthusiasts and visionaries with ideas for
              potential improvements and future expansions, don't hesitate to
              reach out! We want to hear what you have in mind.
            </motion.p>

            <motion.a
              initial={{ opacity: 0, y: '50px' }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : '50px',
              }}
              transition={{
                ease: 'easeOut',
                delay: 0.6,
                duration: 0.4,
              }}
              href="mailto:info@orqafpv.com"
              className="btn btn--primary btn--med"
            >
              Contact us
            </motion.a>
          </div>
        </div>
        <img
          src={require('main/assets/images/fpvonerace/ORQA_MKII_Step-towards_the_future.webp')}
          alt="FPV PILOT visual experience"
          className="fpvpilot__specifications__image--mobile"
        />
      </section>
    )}
  </InView>
);
