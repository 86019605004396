import { RefObject, useEffect } from 'react';

export const useLazyLoad = (
  shouldLoad: boolean,
  setShouldLoad: React.Dispatch<React.SetStateAction<boolean>>,
  placeholderRef: RefObject<HTMLDivElement>,
) => {
  useEffect(() => {
    if (shouldLoad || !placeholderRef.current) return;

    const observer = new IntersectionObserver(([{ intersectionRatio }]) => {
      if (intersectionRatio > 0) {
        setShouldLoad(true);
      }
    });

    observer.observe(placeholderRef.current);
    return () => observer.disconnect();
  }, [shouldLoad, setShouldLoad, placeholderRef]);
};
