import React from 'react';
import { motion } from 'framer-motion';
import InView from 'react-intersection-observer';

import { FPVPilotListItem } from 'main/components';

export const PowerSpecifications: React.FC = () => (
  <InView triggerOnce>
    {({ ref, inView }) => (
      <section className="fpvpilot__specifications--wrapper">
        <motion.h3
          initial={{ opacity: 0, y: '20px' }}
          animate={{
            opacity: inView ? 1 : 0,
            y: inView ? 0 : '20px',
          }}
          transition={{
            ease: 'easeOut',
            duration: 0.4,
          }}
          className="fpvpilot__specifications__title--secondary t-upper"
        >
          Power
        </motion.h3>
        <motion.h2
          initial={{ opacity: 0, y: '30px' }}
          animate={{
            opacity: inView ? 1 : 0,
            y: inView ? 0 : '30px',
          }}
          transition={{
            ease: 'easeOut',
            delay: 0.2,
            duration: 0.4,
          }}
          ref={ref}
          className="fpvpilot__specifications__title--primary t-upper"
        >
          More Power to You
        </motion.h2>
        <div className="fpvpilot__specifications--list">
          <motion.div
            initial={{ opacity: 0, y: '40px' }}
            animate={{
              opacity: inView ? 1 : 0,
              y: inView ? 0 : '40px',
            }}
            transition={{
              ease: 'easeOut',
              delay: 0.4,
              duration: 0.4,
            }}
          >
            <FPVPilotListItem
              title="5V, High power"
              description="Standard receiver bay"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: '40px' }}
            animate={{
              opacity: inView ? 1 : 0,
              y: inView ? 0 : '40px',
            }}
            transition={{
              ease: 'easeOut',
              delay: 0.6,
              duration: 0.4,
            }}
          >
            <FPVPilotListItem
              title="2.1 x 5.5mm barrel connector"
              description="Battery connector"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: '40px' }}
            animate={{
              opacity: inView ? 1 : 0,
              y: inView ? 0 : '40px',
            }}
            transition={{
              ease: 'easeOut',
              delay: 0.8,
              duration: 0.4,
            }}
          >
            <FPVPilotListItem
              title="6-26VDC (2-6S Li-ion Battery)"
              description="Power supply"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: '40px' }}
            animate={{
              opacity: inView ? 1 : 0,
              y: inView ? 0 : '40px',
            }}
            transition={{
              ease: 'easeOut',
              delay: 1,
              duration: 0.4,
            }}
          >
            <FPVPilotListItem
              title="5V, SDIO, Uart"
              description="Secondary bay for additional modules"
            />
          </motion.div>
        </div>
        <div className="fpvpilot__content__image--gradient">
          <motion.img
            initial={{ opacity: 0 }}
            animate={{
              opacity: inView ? 1 : 0,
            }}
            transition={{
              ease: 'easeOut',
              delay: 0.4,
              duration: 0.6,
            }}
            src={require('main/assets/images/fpvonepilot/ORQA_MKII_power.webp')}
            alt="FPV Pilot top view"
            className="fpvpilot__content--image"
          />
        </div>
      </section>
    )}
  </InView>
);
