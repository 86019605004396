import React from 'react';
import { motion } from 'framer-motion';

interface Props {
  title: string;
  description: string;
  inView: boolean;
  titleDelay: number;
  descriptionDelay: number;
}

export const FPVSpecificationsItem: React.FC<Props> = ({
  title,
  description,
  inView,
  titleDelay,
  descriptionDelay,
}) => (
  <div className="fpvonerace__specifications--item">
    <motion.p
      initial={{ opacity: 0, y: '30px' }}
      animate={{
        opacity: inView ? 1 : 0,
        y: inView ? 0 : '30px',
      }}
      transition={{
        ease: 'easeOut',
        delay: titleDelay,
        duration: 0.4,
      }}
      className="fpvonerace__specifications__title--secondary"
    >
      {title}
    </motion.p>
    <motion.p
      initial={{ opacity: 0, y: '30px' }}
      animate={{
        opacity: inView ? 1 : 0,
        y: inView ? 0 : '30px',
      }}
      transition={{
        ease: 'easeOut',
        delay: descriptionDelay,
        duration: 0.4,
      }}
      className=" fpvonerace__specifications__item--wrapper s-left--med"
    >
      {description}
    </motion.p>
  </div>
);
