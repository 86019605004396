import * as React from 'react';
import { Link } from 'react-router-dom';

import { LazyImage } from './LazyImage';

export const Footer: React.FC = () => (
  <footer className="footer" role="contentinfo">
    <div className="wrapper">
      <div className="footer__container">
        <span className="footer__line" />
        <section className="footer__branding">
          <LazyImage
            src={require('main/assets/images/logo-orqa-symbol.svg')}
            alt="ORQA logo"
          />
          <p className="footer__description">
            High End equipment for FPV drones.
          </p>
          <img
            src={require('main/assets/images/iso-9001-certificate.webp')}
            alt="ISO 9001 certificate"
            className="footer__certificate"
          />
        </section>

        <section className="footer__section footer__reseller">
          <div>
            <h2 className="t-tiny t-upperext o-40 s-bottom--lrg">Resellers</h2>
          </div>
          <div>
            <a
              href="https://orqafpv.odoo.com/contact-form"
              target="_blank"
              rel="noopener noreferrer"
              className="t-zeta"
            >
              Become a reseller
            </a>
          </div>
          <div>
            <Link to="/resellers" className="t-zeta">
              List of resellers
            </Link>
          </div>
        </section>
        <section className="footer__section footer__resources">
          <div>
            <h2 className="t-tiny t-upperext o-40 s-bottom--lrg">Resources</h2>
          </div>
          <div>
            <div>
              <Link to="/downloads" className="t-zeta">
                Downloads
              </Link>
            </div>
            <div>
              <Link to="/manual" className="t-zeta">
                Manual
              </Link>
            </div>
            <div>
              <Link to="/blog/" className="t-zeta">
                Blog
              </Link>
            </div>
          </div>
        </section>
        <section className="footer__section footer__support">
          <div>
            <h2 className="t-tiny t-upperext o-40 s-bottom--lrg">Support</h2>
          </div>
          <div>
            <a
              href="http://bit.ly/OrqaSupportTicket"
              target="_blank"
              rel="noopener noreferrer"
              className="t-zeta"
            >
              Submit ticket
            </a>
          </div>
          <div>
            <Link to="/privacy-policy" className="t-zeta">
              Privacy Policy
            </Link>
          </div>
        </section>
        <section className="footer__section">
          <div>
            <h2 className="t-tiny t-upperext o-40 s-bottom--lrg">
              Sponsorships
            </h2>
          </div>
          <div className="s-bottom--lrg">
            <a
              href="https://orqafpv.odoo.com/hr/contactus"
              target="_blank"
              rel="noopener noreferrer"
              className="t-zeta"
            >
              Become Our Pilot
            </a>
            <Link to="/pilots" className="t-zeta">
              List of Pilots
            </Link>
          </div>
          <div className="footer__presskit">
            <div>
              <h2 className="t-tiny t-upperext o-40 s-bottom--lrg">
                Press kit
              </h2>
            </div>
            <div>
              <a
                href="https://dashboard.mailerlite.com/forms/110729/92299545119033038/share"
                target="_blank"
                rel="noopener noreferrer"
                className="t-zeta"
              >
                Request Press kit
              </a>
            </div>
          </div>
        </section>

        <section className="footer__section footer__presskit--mobile">
          <div>
            <h2 className="t-tiny t-upperext o-40 s-bottom--lrg">Press kit</h2>
          </div>
          <div>
            <a
              href="https://dashboard.mailerlite.com/forms/110729/92299545119033038/share"
              target="_blank"
              rel="noopener noreferrer"
              className="t-zeta"
            >
              Request Press kit
            </a>
          </div>
        </section>

        <section className="footer__section footer__info">
          <div>
            <h2 className="t-tiny t-upperext o-40 s-bottom--lrg">More</h2>
          </div>
          <div>
            <Link to="/company-culture" className="t-zeta">
              Company Culture
            </Link>
          </div>
          <div>
            <Link to="/jobs" className="t-zeta">
              Jobs
            </Link>
          </div>
          <a
            href="https://share.hsforms.com/1sHyZ88XrRZC-Z00-Tf5aCQ5d8o5"
            target="_blank"
            rel="noopener noreferrer"
            className="t-zeta"
          >
            Goggle registration
          </a>
          <a
            href="https://enterprise.orqafpv.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="t-zeta"
          >
            Enterprise
          </a>
        </section>
        <section className="footer__section">
          <div>
            <h2 className="t-tiny t-upperext o-40 s-bottom--lrg t-nowrap">
              EU Projects
            </h2>
          </div>
          <div>
            <Link to="/eu-projects/esif" className="t-zeta">
              <span className="t-upper">Esif</span> financijski instrumenti
            </Link>
            <Link to="/eu-projects/eu-Internacionalizacija" className="t-zeta">
              Internacionalizacija rješenja ORQA
            </Link>
          </div>
        </section>
      </div>
    </div>
    <div className="wrapper footer__zicer">
      <LazyImage
        className="footer__zicer-logo"
        src={require('main/assets/images/zicer-logo.png')}
        alt="ZICER logo"
      />
      <p className="footer__zicer__text">
        Član Zagrebačkog inovacijskog centra d.o.o.
      </p>
    </div>
    <div className="wrapper">
      <section className="footer__copy">
        <p className="t-zeta">
          Copyright © 2023 Orqa Ltd. All rights reserved.
        </p>
      </section>
    </div>
  </footer>
);
