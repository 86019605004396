import gql from 'graphql-tag';

export const GET_BLOG_POSTS = gql`
  query blogPosts($after: String, $before: String) {
    allBlog_posts(after: $after, before: $before, sortBy: published_date_DESC) {
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
      edges {
        node {
          _meta {
            uid
          }
          title
          short_content
          long_content
          author
          published_date
          hero_image
          category {
            ... on Blog_category {
              category
            }
          }
          featured_post
        }
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query products {
    allProducts {
      edges {
        node {
          _meta {
            uid
          }
          product_title
          short_product_description
          long_product_description
          featured_image
        }
      }
    }
  }
`;

export const GET_SINGLE_POST = gql`
  query blogPost($uid: String!) {
    blog_post(uid: $uid, lang: "en-gb") {
      title
      short_content
      long_content
      author
      published_date
      hero_image
    }
  }
`;

export const GET_FIRMWARE = gql`
  query firmware {
    allFirmwares {
      edges {
        node {
          name
          version
          date
          firmware {
            ... on _FileLink {
              url
            }
          }
        }
      }
    }
  }
`;

export const GET_DRIVERS = gql`
  query drivers {
    allDriverss {
      edges {
        node {
          name
          version
          date
          driver {
            ... on _FileLink {
              url
            }
          }
        }
      }
    }
  }
`;

export const GET_MANUALS = gql`
  query manuals {
    allManuals {
      edges {
        node {
          title
          manual_version
          date
          link_to_manual {
            ... on _FileLink {
              url
            }
          }
        }
      }
    }
  }
`;

export const GET_RESELLERS = gql`
  query resselers {
    allResellers {
      edges {
        node {
          title
          _meta {
            uid
          }
          link_to_store {
            ... on _ExternalLink {
              url
            }
          }
          country
        }
      }
    }
  }
`;
