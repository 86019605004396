import { updateCanvasZoomImages } from './updateCanvasZoomImages';

export function scrollZoomListener(
  canvas: HTMLCanvasElement,
  context: CanvasRenderingContext2D,
  canvasWrapper: HTMLDivElement,
  frameCount: number,
  images: HTMLImageElement[],
  setCurrentImageIndex: (index: number) => void,
) {
  const scrollTop = window.scrollY;
  const elementTop = canvasWrapper.offsetTop;
  const elementBottom = elementTop + canvasWrapper.scrollHeight;
  const windowBottom = scrollTop + window.innerHeight;
  const totalHeight =
    Math.max(elementBottom, elementTop) - Math.min(windowBottom, scrollTop);
  const windowComp = totalHeight - window.innerHeight;
  const elementInView = canvasWrapper.scrollHeight - windowComp;
  const scrollFraction = elementInView / canvasWrapper.scrollHeight;
  const frameIndex = Math.min(
    frameCount - 1,
    Math.ceil(scrollFraction * frameCount),
  );

  setCurrentImageIndex(frameIndex);

  requestAnimationFrame(() =>
    updateCanvasZoomImages(frameIndex, images, context, canvas),
  );

  return () => cancelAnimationFrame(frameCount);
}
