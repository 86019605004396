import AppoloClient from 'apollo-client';
import { PrismicLink } from 'apollo-link-prismic';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';

const prismicLink = 'https://orqa.prismic.io/graphql';

const client: AppoloClient<NormalizedCacheObject> = new AppoloClient({
  link: PrismicLink({
    uri: prismicLink,
  }),
  cache: new InMemoryCache(),
});

export default client;
