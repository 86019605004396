export function extractNumberFromString(word: string | number) {
  if (typeof word === 'number') return word;

  const match = word.match(/\d+/);

  if (!match) {
    return;
  }

  return match[0];
}
