import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, ApplicationState } from 'modules/redux-store';
import { LoadingSpinner } from 'main/components';

import { CartActions, CartThunks } from '../redux';

interface FixedCartInterface {
  id: string;
  quantity: number;
  title: string;
  variant: {
    price: string;
    priceV2: { amount: string; countryCode: string };
    title: string;
  };
}
export const CartDrawer: React.FC = () => {
  const { isOpen, isChanging, checkout } = useSelector(
    (state: ApplicationState) => state.cart,
  );
  const items =
    ((checkout?.lineItems as unknown) as FixedCartInterface[]) || [];

  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className={`drawer ${isOpen ? 'drawer--open' : ''}`}>
      <div className="drawer__top">
        <button
          className="drawer__close btn btn--primary btn--sml"
          onClick={closeDrawer}
        >
          X
        </button>
        <h1 className="t-gamma s-bottom--lrg">Cart</h1>
      </div>

      <div className="drawer__bottom">
        {isChanging ? (
          <LoadingSpinner />
        ) : (
          <div className="drawer__items">
            {items.map(item => (
              <div key={item.id} className="drawer__item">
                <div className="drawer__item__title">
                  <h2 className="t-zeta t-sans">{item.title}</h2>
                  <p className="drawer__itemm__variant t-zeta o-60">
                    {item.variant.title !== 'Default Title'
                      ? item.variant.title
                      : null}
                  </p>
                </div>
                <div className="drawer__item__quantity">
                  <span className="t-zeta">{item.quantity} item</span>
                  <button
                    className="btn btn--dark btn--tny"
                    data-id={item.id}
                    data-quantity={item.quantity}
                    disabled={item.quantity < 2}
                    onClick={decreaseQuantity}
                  >
                    -
                  </button>
                  <button
                    className="btn btn--dark btn--tny"
                    data-id={item.id}
                    data-quantity={item.quantity}
                    onClick={increaseQuantity}
                  >
                    +
                  </button>
                </div>
                <p className="t-epsilon drawer__item__price">
                  Price: $
                  {(Number(item.variant.price) * item.quantity).toFixed(2)}
                </p>
                <button
                  className="btn btn--primary btn--rounded btn--tny"
                  data-id={item.id}
                  data-quantity={item.quantity}
                  onClick={removeItem}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="drawer__total">
          <p className="t-delta">Total price: ${checkout?.subtotalPrice}</p>
          <p className="t-tiny s-top--sml">
            Tax and shipping calculated on checkout.
          </p>
        </div>
        <div className="drawer__actions">
          <form method="get" action={(checkout as any)?.webUrl}>
            <button
              type="submit"
              className="btn btn--primary btn--med"
              disabled={!items.find(i => i.id)}
            >
              Checkout
            </button>
          </form>
        </div>
      </div>
    </div>
  );

  function closeDrawer() {
    dispatch(CartActions.close());
  }

  function increaseQuantity(event: React.MouseEvent<HTMLButtonElement>) {
    const { id, quantity } = event.currentTarget.dataset;
    if (!id || !quantity) return;

    dispatch(CartThunks.increaseQuantity(id, parseInt(quantity)));
  }

  function decreaseQuantity(event: React.MouseEvent<HTMLButtonElement>) {
    const { id, quantity } = event.currentTarget.dataset;
    if (!id || !quantity) return;

    dispatch(CartThunks.decreaseQuantity(id, parseInt(quantity)));
  }

  function removeItem(event: React.MouseEvent<HTMLButtonElement>) {
    const { id, quantity } = event.currentTarget.dataset;
    if (!id || !quantity) return;

    dispatch(CartThunks.removeItem(id, parseInt(quantity)));
  }
};
