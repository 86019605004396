import React from 'react';

import { PilotItem, pilotList } from 'modules/fpv-one-pilot';

export const Pilots: React.FC = () => {
  return (
    <section className="page">
      <div className="pilots">
        <header className="pilots__header">
          <p className="pilots__label t-upper t-zeta">ORQA Pilots</p>
          <h1 className="t-huge t-center t-upper s-bottom--lrg">
            Meet Our Sponsored Aces
          </h1>
          <p className="pilots__description t-delta t-center">
            Dive into the world of FPV excellence and discover the incredible
            talents who trust our goggles to conquer the skies.
          </p>
        </header>
        <div className="pilots__list">
          {pilotList.map(pilot => (
            <PilotItem key={pilot.handle} pilot={pilot} />
          ))}
        </div>
      </div>
    </section>
  );
};
