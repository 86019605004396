import React from 'react';
import { motion } from 'framer-motion';
import InView from 'react-intersection-observer';

import { LazyImage } from 'main/components';

export const AlexCampbellSpotlight: React.FC = () => (
  <InView triggerOnce={false}>
    {({ ref, inView }) => (
      <div className="fpvrace--testimonial fpvrace__testimonial--secondary">
        <LazyImage
          src={require('main/assets/images/fpvonerace/alex-campbell-testimonial.webp')}
          alt="Pilot spotlight"
          className="fpvpilot__spotlight--mobile__img--secondary"
        />
        <div ref={ref} className="fpvrace__spotlight--wrapper">
          <div className="fpvrace__spotlight--content">
            <motion.h2
              ref={ref}
              initial={{ opacity: 0, y: '20px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '20px' }}
              transition={{ ease: 'circOut', duration: 0.3 }}
              className="fpvpilot__spotlight--title"
            >
              The best for the best!
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '40px' }}
              transition={{ ease: 'circOut', duration: 0.3, delay: 0.3 }}
              className="fpvpilot__spotlight--description"
            >
              “When it comes to racing, there’s no point in me using anything
              but the best. That’s why you only see me using ORQA goggles.”
            </motion.p>
            <motion.hr
              initial={{ width: 0 }}
              animate={{
                width: inView ? '100%' : '0',
              }}
              transition={{ ease: 'easeOut', duration: 0.3, delay: 0.3 }}
              className="fpvpilot__spotlight--divider"
            />

            <div className="fpvpilot__testimonial--wrapper">
              <motion.div
                initial={{ opacity: 0, y: '40px' }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : '40px',
                }}
                transition={{ ease: 'circOut', duration: 0.3, delay: 0.6 }}
                className="fpvpilot__testimonial--content"
              >
                <LazyImage
                  src={require('main/assets/images/fpvonerace/alex-campbell.webp')}
                  alt="Alex Campbell"
                  className="fpvpilot__testimonial--image"
                />
                <div>
                  <h3 className="fpvpilot__testimonial--description">
                    AlexFPV
                  </h3>
                  <p className="fpvpilot__testimonial--title">Alex Campbell</p>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    )}
  </InView>
);
