import React, { ImgHTMLAttributes, useRef, useState } from 'react';

import { useLazyLoad } from 'main/hooks/useLazyLoad';

type ImageProps = ImgHTMLAttributes<HTMLImageElement>;

export const LazyImage = (imageProps: ImageProps) => {
  const [shouldLoad, setShouldLoad] = useState(false);
  const placeholderRef = useRef<HTMLDivElement>(null);

  useLazyLoad(shouldLoad, setShouldLoad, placeholderRef);

  return shouldLoad ? (
    <img alt={imageProps.alt} {...imageProps} />
  ) : (
    <div ref={placeholderRef} />
  );
};
