import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';

import { LazyImage } from 'main/components';

import { extractNumberFromString } from '../utils';

interface Props {
  item: ShopifyBuy.Product & { price?: string; image?: string };
}

export const ProductListItem: React.FC<Props> = ({ item }) => {
  const [chosenVariant, setVariant] = useState({
    value: item.variants[0].id,
    label: item.variants[0].title,
    price: item.variants[0].price,
    available: item.variants[0].available,
    image: item.variants[0].image.src,
  });
  const url = useMemo(
    () =>
      `${item.onlineStoreUrl}${
        chosenVariant.available
          ? `?variant=${extractNumberFromString(chosenVariant.value)}`
          : ''
      }`,
    [chosenVariant.available, chosenVariant.value, item.onlineStoreUrl],
  );

  const handleVariantChange = (variant: any) => {
    setVariant(variant);
  };

  const variantOptions = item.variants.map(option => {
    return {
      value: option.id,
      label: option.title,
      price: option.price,
      available: option.available,
      image: option.image.src,
    };
  });

  // function description here
  function displayDescription() {
    const maxLength = 1000;

    const trimDescription = item.description.substr(0, maxLength);
    return item.description.length > maxLength
      ? trimDescription.substr(
          0,
          Math.min(trimDescription.length, trimDescription.lastIndexOf('. ')),
        ) + '...'
      : item.description;
  }

  return (
    <article className="productblock t-negative">
      <div className="productblock__visual">
        <LazyImage
          src={chosenVariant.image}
          alt={(item.images[0] as any).altText}
        />
      </div>
      <div className="productblock__content">
        <header className="productblock__heading">
          <h1 className="t-delta s-bottom--sml">
            <Link to={`/fpvdetails/${item.id}`}>{item.title}</Link>
          </h1>
          <div className="t-zeta o-60">${chosenVariant.price}</div>
        </header>
        <div className="productblock__description formatted s-bottom--lrg">
          <p>{displayDescription()}</p>
        </div>

        {variantOptions.length > 1 && (
          <div>
            <Select
              options={variantOptions}
              value={chosenVariant}
              onChange={handleVariantChange}
              isOptionDisabled={option => !option.available}
            />
          </div>
        )}
        <footer className="productblock__footer">
          {chosenVariant.available ? (
            <a
              href={url}
              className="btn btn--primary btn--med s-bottom--med"
              target="_blank"
              rel="noopener noreferrer"
            >
              Buy now
            </a>
          ) : (
            <span className="productblock__sold-out">Sold out</span>
          )}
        </footer>
      </div>
    </article>
  );
};
