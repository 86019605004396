import * as React from 'react';
import { useLocation } from 'react-router';

export const PageNavFPVConnect: React.FC = () => {
  const location = useLocation();

  if (location.pathname !== '/fpvconnect') {
    return null;
  }

  return (
    <nav className="pagenav">
      <ul>
        <li>
          <a href="#DVR">Access the DVR</a>
        </li>
        <li>
          <a href="#InstallUpdates">Install updates</a>
        </li>
        <li>
          <a href="#FPVConnectInAction">FPV.Connect in Action</a>
        </li>
      </ul>
      <a
        href="https://shop.orqafpv.com/products/orqa-orqa-fpv-connect"
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn--primary btn--tny"
      >
        Buy now
      </a>
    </nav>
  );
};
