import Client from 'shopify-buy';

import { ActionUnion, createAction } from 'modules/redux-store';
import { ProductItem } from 'modules/products';

import { CartTypes } from './types';

const Actions = {
  isChanging: () => createAction(CartTypes.isChanging),
  open: () => createAction(CartTypes.Open),
  close: () => createAction(CartTypes.Close),
  createClient: (client: Client.Client) =>
    createAction(CartTypes.CreateClient, { client }),
  createCheckout: (checkout: Client.Cart) =>
    createAction(CartTypes.CreateCheckout, { checkout }),
  addItem: (items: ProductItem[]) => createAction(CartTypes.AddItem, { items }),
  removeItem: (items: ProductItem[]) =>
    createAction(CartTypes.RemoveItem, { items }),
};

export const CartActions = {
  ...Actions,
};

export type CartActions = ActionUnion<typeof CartActions>;
