import React, { useEffect, useCallback } from 'react';
import { TweenMax, TimelineLite, Expo } from 'gsap';
import ScrollMagic from 'scrollmagic';
import { useSelector } from 'react-redux';

import { ProductListItem, accesories } from 'modules/products';
import { ApplicationState } from 'modules/redux-store';
import { LoadingSpinner } from 'main/components';
import { LazyImage } from 'main/components/LazyImage';

const scrollMagicController = new ScrollMagic.Controller();

interface State {
  loading: boolean;
  isMobile: boolean;
  items: ShopifyBuy.Product[];
}

export const FPVAccessories: React.FC = () => {
  const [state, setState] = React.useState<State>({
    loading: true,
    isMobile: false,
    items: [],
  });

  const { client } = useSelector((state: ApplicationState) => state.cart);

  const fetchItems = useCallback(async (client?: ShopifyBuy.Client) => {
    if (!client) return;

    const itemPromises = accesories.map(accessory => {
      const result = client.product.fetch(accessory.id);
      return result;
    });

    const result = await Promise.all(itemPromises);
    setState(prevState => ({
      ...prevState,
      items: result.filter(item => item !== null),
    }));
  }, []);

  useEffect(() => {
    fetchItems(client);
  }, [client, fetchItems]);

  useEffect(() => {
    const isMobile = window.innerWidth <= 991;

    if (!isMobile) {
      // Animate splash
      // Animate splash
      // Animate splash
      const animateSplash = new TimelineLite();

      animateSplash
        .add(
          TweenMax.fromTo(
            '.splash__content h1',
            1,
            {
              opacity: 0,
              y: 40,
            },
            {
              opacity: 1,
              y: 0,
              ease: Expo.easeOut,
            },
          ),
          '=+1',
        )
        .fromTo(
          '.splash__content p',
          2,
          {
            opacity: 0,
            y: 40,
          },
          {
            opacity: 1,
            y: 0,
            ease: Expo.easeOut,
          },
          '-=0.5',
        )
        .staggerFromTo(
          '.splash__grid > p',
          1,
          {
            opacity: 0,
            y: 40,
          },
          {
            opacity: 0.8,
            y: 0,
            ease: Expo.easeOut,
          },
          0.2,
          '-=1',
        );
    }

    // Newsletter animation
    // Newsletter animation
    // Newsletter animation
    const animateNewsletter = new TimelineLite();
    animateNewsletter.pause();

    animateNewsletter
      .add(
        TweenMax.staggerFromTo(
          '#newsletter .newsletter__heading *',
          1.5,
          {
            opacity: 0,
            y: 60,
          },
          {
            opacity: 1,
            y: 0,
            ease: Expo.easeOut,
          },
          0.2,
        ),
      )
      .fromTo(
        '#newsletter .newsletter__form',
        2,
        {
          opacity: 0,
          y: 60,
        },
        {
          opacity: 1,
          y: 0,
          ease: Expo.easeOut,
        },
        '-=1',
      )
      .fromTo(
        '.footer__line',
        0.6,
        {
          width: 0,
        },
        {
          width: '100%',
          ease: Expo.easeOut,
        },
        '-=1.5',
      )
      .staggerFromTo(
        '.footer__container > section',
        1.5,
        {
          opacity: 0,
          y: 60,
        },
        {
          opacity: 1,
          y: 0,
          ease: Expo.easeOut,
        },
        0.2,
        '-=1',
      );

    new ScrollMagic.Scene({
      triggerElement: '#newsletter',
      triggerHook: 0.6,
    })
      .on('enter', () => {
        animateNewsletter.play().timeScale(1);
      })
      .addTo(scrollMagicController);
  }, []);

  const { loading, items } = state;
  return (
    <section>
      {loading && <div className="pageswipe" />}

      <section className="splash">
        <div className="splash__visual">
          <LazyImage
            src={require('main/assets/pages/fpvaccessories/fpvaccessories-cover.webp')}
            alt=""
          />
        </div>
        <div className="splash__content">
          <div className="wrapper wrapper--narrow">
            <h1 className="t-gamma t-primary s-bottom--med">FPV.Accessories</h1>
            <p className="t-zeta o-80">
              Next level accessories for the next level flying experience.
            </p>
          </div>
        </div>
      </section>
      {!items ? (
        <LoadingSpinner />
      ) : (
        <div className="u-bg--light">
          <div className="wrapper wrapper--wide">
            <section className="productlist">
              {items.map(item => (
                <ProductListItem key={item.id} item={item} />
              ))}
            </section>
          </div>
        </div>
      )}
    </section>
  );
};
