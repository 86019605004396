import { RichText } from 'prismic-reactjs';
import React from 'react';
import { useQuery } from 'react-apollo';
import { useParams } from 'react-router';

import { GET_SINGLE_POST } from 'main/graphql';
import { LoadingSpinner, SEO } from 'main/components';

interface Params {
  slug?: string;
}

type Props = Params;

export const BlogDetails: React.FC<Props> = () => {
  const { slug } = useParams<Props>();
  const { data, loading, error } = useQuery(GET_SINGLE_POST, {
    variables: {
      uid: slug,
    },
  });

  return (
    <article className="blog-details">
      <div>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <SEO
              seoTitle={`ORQA - ${data.blog_post?.title?.[0]?.text}`}
              seoDescription={`${data.blog_post?.short_content?.[0]?.text}`}
            />
            {error && <p>Error...</p>}
            {data && data.blog_post && (
              <div className="blog-details__wrapper">
                {data.blog_post.hero_image?.url && (
                  <div
                    className="blog-details__hero"
                    style={{
                      backgroundImage: `url(${data.blog_post.hero_image.url})`,
                    }}
                  >
                    <div className="blog-details__hero__title">
                      <div className="wrapper wrapper--narrow">
                        <h1 className="t-beta s-bottom--med">
                          {data.blog_post.title[0]?.text}
                        </h1>
                        <div className="blog-details__meta">
                          <p className="t-tiny t-upper o-80">
                            <span className="s-right--lrg">
                              {data.blog_post.published_date}
                            </span>
                            <span>{data.blog_post.author}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="blog-details__content">
                  <div className="wrapper wrapper--narrow">
                    {data.blog_post?.short_content && (
                      <div className="formatted s-bottom--xlrg">
                        <RichText render={data.blog_post?.short_content} />
                      </div>
                    )}

                    <div className="formatted">
                      <RichText render={data.blog_post.long_content} />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!data.blog_post && <p>Something went wrong...</p>}
          </>
        )}
      </div>
    </article>
  );
};
