import { Pilot } from '../models';

export const pilotList: Pilot[] = [
  {
    name: 'Anthony Knight',
    handle: 'AyyyKayyy',
    country: 'USA',
    imgSrc: 'ayyykayyy.webp',
    socials: {
      fb: 'https://www.facebook.com/AKEventVibe',
      ig: 'https://www.instagram.com/ayyykayyyfpv/',
    },
  },
  {
    name: 'Benjamin Heimisson',
    handle: 'BenjaminFPV',
    country: 'Iceland',
    imgSrc: 'benjamin.webp',
    socials: {
      fb: 'https://www.facebook.com/benjaminheimis',
      ig: 'https://www.instagram.com/benjamin.fpv/',
      tikTok:
        'https://www.tiktok.com/@benjamin_fpv?is_from_webapp=1&sender_device=pc',
    },
  },
  {
    name: 'Charles Rinfret',
    handle: 'Paco',
    country: 'Canada',
    imgSrc: 'paco.webp',
    socials: {
      fb: 'https://www.facebook.com/charles.rinfret',
      ig: 'https://www.instagram.com/thepacofpv/',
      yt: 'https://www.youtube.com/@pacofpv8517',
    },
  },
  {
    name: 'Dominik Dejanović',
    handle: 'Domi_FPV',
    country: 'Croatia',
    imgSrc: 'dominik.webp',
    socials: {
      fb: 'https://www.facebook.com/dominik.dejanovic.1',
      ig: 'https://www.instagram.com/domii.62/',
    },
  },
  {
    name: 'Huseyin Yilmaz Çimen',
    handle: 'Klarfon',
    country: 'Turkey',
    imgSrc: 'cimen.webp',
    socials: {
      fb: 'https://www.facebook.com/klarfon',
      ig: 'https://www.instagram.com/klarfon/',
    },
  },
  {
    name: 'Noah Pitzer',
    handle: 'CustomRCmods',
    country: 'USA',
    imgSrc: 'pitzer.webp',
    socials: {
      fb: 'https://www.facebook.com/noah.pitzer.712',
      ig: 'https://instagram.com/customrcmodsofficial',
      tikTok:
        'https://www.tiktok.com/@benjamin_fpv?is_from_webapp=1&sender_device=pc',
    },
  },
  {
    name: 'Peter Rudman',
    handle: 'NightwingFPV',
    country: 'USA',
    imgSrc: 'nightwing.webp',
    socials: {
      fb: 'https://www.facebook.com/petethefeet123',
      ig: 'https://www.instagram.com/nightwingfpv/',
    },
  },
  {
    name: 'Svanur Gabriele Monaco',
    handle: 'SvanurFPV',
    country: 'Iceland',
    imgSrc: 'svanur.webp',
    socials: {
      fb: 'https://www.facebook.com/svanur.gabriele',
      ig: 'https://www.instagram.com/svanurfpv/',
      tikTok:
        'https://www.tiktok.com/@svanur_fpv?is_from_webapp=1&sender_device=pc',
    },
  },
  {
    name: 'Wanraya Wannapong',
    handle: 'MilkFPV',
    country: 'Thailand',
    imgSrc: 'milk.webp',
    socials: {
      fb: 'https://www.facebook.com/wanraya.wannapong',
      ig: 'https://www.instagram.com/milk__fpv/',
    },
  },
  {
    name: 'Solay Sayce',
    handle: 'DerpyHooves',
    country: 'UK',
    imgSrc: 'sayce.webp',
    socials: {
      fb: 'https://www.instagram.com/derpyhooves.fpv/',
      ig: 'https://www.facebook.com/soley.sayce',
      yt: 'https://www.youtube.com/@Derpy.Hooves',
    },
  },
  {
    name: 'Kaden Steckenrider',
    handle: 'FlyboyFPV',
    country: 'USA',
    imgSrc: 'kaden.webp',
    socials: {
      fb: 'https://www.facebook.com/kaden.steckenrider.1',
      ig: 'https://www.instagram.com/flyboy2009fpv/',
    },
  },
];
