import React from 'react';
import { motion } from 'framer-motion';
import InView from 'react-intersection-observer';

export const FPVVideo: React.FC = () => (
  <InView triggerOnce={false}>
    {({ ref, inView }) => (
      <motion.video
        ref={ref}
        src="https://firebasestorage.googleapis.com/v0/b/orqa-79270.appspot.com/o/orqa-town-from-air.mp4?alt=media&token=ee669748-b721-4688-89ba-1b96d7fb4494"
        className="fpvpilot__video"
        initial={{ opacity: 0.6, width: '70%' }}
        animate={{
          opacity: inView ? 1 : 0.6,
          width: inView ? '100%' : '70%',
        }}
        transition={{ ease: 'easeOut', duration: 0.6, delay: 0.3 }}
        muted
        autoPlay
        loop
        playsInline
      />
    )}
  </InView>
);
