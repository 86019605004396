import React from 'react';
import { motion } from 'framer-motion';
import InView from 'react-intersection-observer';

export const Technology: React.FC = () => (
  <InView triggerOnce>
    {({ ref, inView }) => (
      <section className="fpvpilot__splitsection">
        <div ref={ref} className="fpvpilot__splitsection--left">
          <motion.h3
            initial={{ opacity: 0, y: '20px' }}
            animate={{
              opacity: inView ? 1 : 0,
              y: inView ? 0 : '20px',
            }}
            transition={{
              ease: 'easeOut',
              duration: 0.4,
            }}
            className="fpvpilot__splitsection__title--secondary t-upper"
          >
            Technology
          </motion.h3>
          <motion.h2
            initial={{ opacity: 0, y: '40px' }}
            animate={{
              opacity: inView ? 1 : 0,
              y: inView ? 0 : '40px',
            }}
            transition={{
              ease: 'easeOut',
              delay: 0.2,
              duration: 0.3,
            }}
            className="fpvpilot__splitsection__title--primary t-upper"
          >
            Faster than your fastest lap.
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: '50px' }}
            animate={{
              opacity: inView ? 1 : 0,
              y: inView ? 0 : '50px',
            }}
            transition={{
              ease: 'easeOut',
              delay: 0.4,
              duration: 0.4,
            }}
            className="fpvpilot__splitsection--description"
          >
            Lenses always at their foggiest right when things start to heat up?
            No more - instant defogging, in a breeze.
          </motion.p>
        </div>
        <div className="fpvpilot__splitsection--right">
          <motion.img
            initial={{ opacity: 0 }}
            animate={{
              opacity: inView ? 1 : 0,
            }}
            transition={{
              ease: 'easeOut',
              delay: 0.4,
              duration: 0.6,
            }}
            src={require('main/assets/images/fpvonerace/ORQA_MKII_Lenses.webp')}
            alt="Fpv Pilot back side"
            className="fpvpilot__splitsection--image"
          />
        </div>
      </section>
    )}
  </InView>
);
