import { AppDispatch, ApplicationState } from 'modules/redux-store';

import { CartActions } from './actions';

const addItemToCart = (id: string) => async (
  dispatch: AppDispatch,
  getState: () => ApplicationState,
) => {
  const { client, checkout } = getState().cart;
  if (!client || !checkout?.id) return;
  dispatch(CartActions.isChanging());
  dispatch(CartActions.open());

  const lineItemToAdd = [
    {
      variantId: id,
      quantity: 1,
    },
  ];

  await client.checkout
    .addLineItems(checkout.id, lineItemToAdd)
    .then((checkout: ShopifyBuy.Cart) => {
      dispatch(CartActions.createCheckout(checkout));
    });
};

const increaseQuantity = (itemId: string, currentQuantity: number) => async (
  dispatch: AppDispatch,
  getState: () => ApplicationState,
) => {
  const { client, checkout } = getState().cart;
  if (!client || !checkout?.id) return;
  dispatch(CartActions.isChanging());

  const lineItemToUpdate = [{ id: itemId, quantity: currentQuantity + 1 }];

  await (client as any).checkout
    .updateLineItems(checkout.id.toString(), lineItemToUpdate)
    .then((checkout: ShopifyBuy.Cart) => {
      dispatch(CartActions.createCheckout(checkout));
    });
};

const decreaseQuantity = (itemId: string, currentQuantity: number) => async (
  dispatch: AppDispatch,
  getState: () => ApplicationState,
) => {
  const { client, checkout } = getState().cart;
  if (!client || !checkout?.id) return;
  dispatch(CartActions.isChanging());

  const lineItemToUpdate = [
    {
      id: itemId,
      quantity: currentQuantity > 1 ? currentQuantity - 1 : null,
    },
  ];

  await (client as any).checkout
    .updateLineItems(checkout.id.toString(), lineItemToUpdate)
    .then((checkout: ShopifyBuy.Cart) => {
      dispatch(CartActions.createCheckout(checkout));
    });
};

const removeItem = (itemId: string, currentQuantity: number) => async (
  dispatch: AppDispatch,
  getState: () => ApplicationState,
) => {
  const { client, checkout } = getState().cart;
  if (!client || !checkout?.id) return;
  dispatch(CartActions.isChanging());

  const lineItemToUpdate = [
    { id: itemId, quantity: currentQuantity - currentQuantity },
  ];

  await (client as any).checkout
    .updateLineItems(checkout.id.toString(), lineItemToUpdate)
    .then((checkout: ShopifyBuy.Cart) => {
      dispatch(CartActions.createCheckout(checkout));
    });
};

export const CartThunks = {
  addItemToCart,
  increaseQuantity,
  decreaseQuantity,
  removeItem,
};
