import React from 'react';
import { motion } from 'framer-motion';
import InView from 'react-intersection-observer';

import { FPVPilotListItem } from 'main/components';

export const OpticsEngine: React.FC = () => (
  <InView triggerOnce>
    {({ ref, inView }) => (
      <section className="fpvonerace__visual--experience fpvonerace__visual__experience--secondary">
        <motion.img
          initial={{ opacity: 0 }}
          animate={{
            opacity: inView ? 1 : 0,
          }}
          transition={{
            ease: 'easeOut',
            delay: 0.4,
            duration: 0.6,
          }}
          src={require('main/assets/images/fpvonerace/ORQA_MKII_optic-engine.webp')}
          alt="FPV PILOT visual experience"
          className="fpvonerace__specifications__image--absolute"
        />
        <div className="fpvrace__list--wrapper">
          <div className="fpvonerace__list__content--secondary">
            <motion.h3
              initial={{ opacity: 0, y: '20px' }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : '20px',
              }}
              transition={{
                ease: 'easeOut',
                duration: 0.4,
              }}
              className="fpvonepilot__visual__title--secondary t-upper"
            >
              New optics engine
            </motion.h3>
            <motion.h2
              ref={ref}
              initial={{ opacity: 0, y: '30px' }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : '30px',
              }}
              transition={{
                ease: 'easeOut',
                delay: 0.2,
                duration: 0.4,
              }}
              className="fpvonerace__specifications--title t-upper"
            >
              Eyes on the track!
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: '50px' }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : '50px',
              }}
              transition={{
                ease: 'easeOut',
                delay: 0.4,
                duration: 0.4,
              }}
              className="fpvpilot__splitsection--description s-bottom--xlrg"
            >
              New optics engine and adjusted FOV enable fine-tuning capabilities
              and more focus than ever.
            </motion.p>
            <div className="fpvonerace__list--wrapper fpvpilot__list__row--template-3">
              <motion.div
                initial={{ opacity: 0, y: '40px' }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : '40px',
                }}
                transition={{
                  ease: 'easeOut',
                  delay: 0.2,
                  duration: 0.4,
                }}
                className="fpvrace__list__column--left fpvpilot__list__row--1"
              >
                <FPVPilotListItem
                  title="1024 x 768 pixels"
                  description="Screen resolution"
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: '40px' }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : '40px',
                }}
                transition={{
                  ease: 'easeOut',
                  delay: 0.4,
                  duration: 0.4,
                }}
                className="fpvrace__list__column--left fpvpilot__list__row--2"
              >
                <FPVPilotListItem
                  title="4:3 Native"
                  description="Aspect ratio"
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: '40px' }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : '40px',
                }}
                transition={{
                  ease: 'easeOut',
                  delay: 0.6,
                  duration: 0.4,
                }}
                className="fpvrace__list__column--left fpvpilot__list__row--3"
              >
                <FPVPilotListItem
                  title="1280x960, 50/60fps, H.264, mp4"
                  description="Integrated DVR"
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: '40px' }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : '40px',
                }}
                transition={{
                  ease: 'easeOut',
                  delay: 0.8,
                  duration: 0.4,
                }}
                className="fpvrace__list__column--right fpvpilot__list__row--1"
              >
                <FPVPilotListItem
                  title="33° (race mode)"
                  description="Viewing angle"
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: '40px' }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : '40px',
                }}
                transition={{
                  ease: 'easeOut',
                  delay: 1,
                  duration: 0.4,
                }}
                className="fpvrace__list__column--right fpvpilot__list__row--2"
              >
                <FPVPilotListItem
                  title="-4D - +4D"
                  description="Focus adjust"
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: '40px' }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : '40px',
                }}
                transition={{
                  ease: 'easeOut',
                  delay: 1.2,
                  duration: 0.4,
                }}
                className="fpvrace__list__column--right fpvpilot__list__row--3"
              >
                <FPVPilotListItem
                  title="Micro HDMI"
                  description="HDMI video input"
                />
              </motion.div>
            </div>
          </div>
        </div>
        <img
          src={require('main/assets/images/fpvonerace/ORQA_MKII_optic-engine-mobile.webp')}
          alt="FPV PILOT visual experience"
          className="fpvpilot__specifications__image--mobile"
        />
      </section>
    )}
  </InView>
);
