import { currentFrame } from './getCurrentFrame';

export function preloadImages(
  images: any,
  frameCount: number,
  imgPath: string,
) {
  for (let i = 1; i < frameCount; i++) {
    images[i] = new Image();
    images[i].fetchPriority = 'high';
    images[i].src = currentFrame(i, imgPath);
  }
}
