export interface Addon {
  id: string;
  title: string;
  price: string;
  image: string;
  description: string;
  images?: Array<{
    image: string;
    productId: string;
  }>;
  onlineStoreUrl: string;
  variants: Array<{
    value: string;
    label?: string;
    isDisabled?: boolean;
  }>;
}

export const accesories: { id: string }[] = [
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ1MjQwMjU1NDQ3ODY=',
  },
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ1Nzk1NzAwMjQ1MzA=',
  },
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ2ODcyMjk3ODAwNTA=',
  },
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ1MjM5MzY5MDczNDY=',
  },
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ1MTU3NjQ1MDI2MTA=',
  },
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ1MjI5ODYyNDIxMzA=',
  },
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ3NTQxMTI5MzgwNjY=',
  },
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ1ODkwNDA2OTc0MjY=',
  },
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ1Mjg5OTIyMjMzMTQ=',
  },
];

export const antennas: { id: string }[] = [
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzIxNTY3MTYxMzAzNzc=',
  },
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzIxNTY2OTkxMjM3ODU=',
  },
];

export const headsets: { id: string }[] = [
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ1MTU3NzY2NTk1Mzg=',
  },
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ1MTU3MjM2MDgxNDY=',
  },
  {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzIxNjQwMDU0MDQ3NDU=',
  },
];
