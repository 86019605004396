import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import * as React from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

import {
  PageNavFPVConnect,
  PageNavFPVCtrl,
  PageNavFPVOne,
} from 'main/components';

import { ProgressBar } from './ProggressBar';

interface NavigationState {
  isMenuToggled: boolean;
}

type Props = RouteComponentProps<any>;

class Navigation extends React.Component<Props, NavigationState> {
  state = {
    isMenuToggled: false,
  };

  private targetElement: Element | null = null;

  componentDidMount() {
    this.targetElement = document.querySelector('.nav__links');
  }

  componentDidUpdate(prevProps: Props) {
    const { pathname } = this.props.location;

    if (pathname !== prevProps.location.pathname) {
      this.removeBodyLock();
      this.setState({
        isMenuToggled: false,
      });
      document.body.classList.remove('is-locked');
      document.documentElement.classList.remove('is-locked');
    }
  }

  preventDefault = (e: any) => {
    e.preventDefault();
  };

  handleMenuToggle = () => {
    const { isMenuToggled } = this.state;
    this.setState({ isMenuToggled: !isMenuToggled });

    if (isMenuToggled) {
      this.removeBodyLock();
    } else {
      document.body.classList.add('is-locked');
      document.documentElement.classList.add('is-locked');
      disableBodyScroll(this.targetElement);
    }
  };

  removeBodyLock = () => {
    document.body.classList.remove('is-locked');
    document.documentElement.classList.remove('is-locked');
    enableBodyScroll(this.targetElement);
  };

  handleBlur = (event: any) => {
    event.currentTarget ? event.currentTarget.blur() : event.srcElement.blur();
  };

  render() {
    const { isMenuToggled } = this.state;

    return (
      <>
        <nav className={`nav ${isMenuToggled ? 'is-open' : ''}`}>
          <div className={`nav__links ${isMenuToggled ? 'is-open' : ''}`}>
            <ul className="nav__links__top">
              <li className="nav__links__item nav__links__item--sub-nav">
                <p className="nav__link nav__links__item__subtitle nav__links__item__subtitle--secondary">
                  FPV.one
                </p>
                <ul className="nav__links__subnav--primary">
                  <li className="nav__links__subitem">
                    <NavLink
                      to={'/fpvonepilot'}
                      onMouseLeave={this.handleBlur}
                      className="nav__sublink"
                    >
                      <img
                        src={require('main/assets/images/fpvonepilot/ORQA_MKII_SML.webp')}
                        alt=""
                      />
                      <span>Pilot</span>
                    </NavLink>
                  </li>
                  <li className="nav__links__subitem">
                    <NavLink
                      to="/fpvonerace"
                      onMouseLeave={this.handleBlur}
                      exact
                      className="nav__sublink"
                    >
                      <img
                        src={require('main/assets/images/fpvonerace/fpvone-race-white.webp')}
                        alt=""
                      />
                      <span>Race</span>
                    </NavLink>
                  </li>
                  <li className="nav__links__subitem">
                    <NavHashLink
                      to="/fpvonerace#spotlight"
                      exact
                      className="nav__sublink"
                    >
                      <img
                        src={require('main/assets/images/fpvonepilot/orqa-fly-five-sml.webp')}
                        alt=""
                      />
                      <span>Fly Five 33</span>
                    </NavHashLink>
                  </li>
                </ul>
              </li>

              <li className="nav__links__item">
                <NavLink
                  onMouseLeave={this.handleBlur}
                  to="/fpvconnect"
                  exact
                  className="nav__link"
                  activeClassName="active"
                  onClick={this.removeBodyLock}
                >
                  FPV.Connect
                </NavLink>
              </li>

              <li className="nav__links__item">
                <NavLink
                  onMouseLeave={this.handleBlur}
                  to="/fpvctrl"
                  exact
                  className="nav__link"
                  activeClassName="active"
                  onClick={this.removeBodyLock}
                >
                  FPV.Ctrl
                </NavLink>
              </li>

              <li className="nav__links__item">
                <NavLink
                  onMouseLeave={this.handleBlur}
                  to="/about"
                  exact
                  className="nav__link"
                  activeClassName="active"
                  onClick={this.removeBodyLock}
                >
                  About
                </NavLink>
              </li>
              <li className="nav__links__item">
                <NavLink
                  onMouseLeave={this.handleBlur}
                  to="/blog"
                  exact
                  className="nav__link"
                  activeClassName="active"
                  onClick={this.removeBodyLock}
                >
                  Blog
                </NavLink>
              </li>
              <li className="nav__links__item">
                <a
                  onMouseLeave={this.handleBlur}
                  href="https://bit.ly/fpvskydive20"
                  className="nav__link"
                  onClick={this.removeBodyLock}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Skydive
                </a>
              </li>
              <li className="nav__links__item">
                <a
                  href="https://enterprise.orqafpv.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav__link"
                >
                  Enterprise
                </a>
              </li>
              <li className="nav__links__item">
                <a
                  href="https://orqafpv.freshdesk.com/support/solutions"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav__link"
                >
                  Support
                </a>
              </li>
            </ul>
            <div className="social__wrapper">
              <div className="social">
                <a
                  href="https://www.facebook.com/OrqaFPV"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require('main/assets/images/icon-social-facebook.svg')}
                    alt="ORQA on Facebook"
                    className="header__icon"
                  />
                </a>
                <a
                  href="https://www.instagram.com/orqafpv/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require('main/assets/images/icon-social-instagram.svg')}
                    alt="ORQA on Instagram"
                    className="header__icon"
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCGPZJ5j16slPTObilo8viwg/featured"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require('main/assets/images/icon-social-youtube.svg')}
                    alt="ORQA on Youtube"
                    className="header__icon"
                  />
                </a>
                <a
                  href="https://twitter.com/OrqaFPV"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require('main/assets/images/icon-social-twitter.svg')}
                    alt="ORQA on Twitter"
                    className="header__icon"
                  />
                </a>
              </div>
            </div>
          </div>
          <div>
            <div className="nav__toggle">
              <button
                onClick={this.handleMenuToggle}
                className={`hamburger hamburger--elastic ${
                  isMenuToggled ? 'is-active' : ''
                }`}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            </div>
            <div className="nav__proggress--bar">
              <ProgressBar />
            </div>
          </div>
          <PageNavFPVOne />
          <PageNavFPVConnect />
          <PageNavFPVCtrl />
        </nav>
      </>
    );
  }
}

export default withRouter(Navigation);
