import * as React from 'react';
import { useLocation } from 'react-router';

export const PageNavFPVCtrl: React.FC = () => {
  const location = useLocation();

  if (location.pathname !== '/fpvctrl') {
    return null;
  }

  return (
    <nav className="pagenav">
      <ul>
        <li>
          <a href="#DiscoverCtrl">Discover</a>
        </li>
        <li>
          <a href="#LearnByPlaying">Learn by playing</a>
        </li>
        <li>
          <a href="#IRCGhost">IRC ghost</a>
        </li>
        <li>
          <a href="#MobileApp">Mobile app</a>
        </li>
      </ul>
    </nav>
  );
};
