import React from 'react';

import { SEO } from 'main/components';

export const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <SEO seoTitle="ORQA - Privacy Policy" />
      <section className="page">
        <div className="wrapper wrapper--narrow">
          <h1 className="t-beta s-bottom--med">Privacy Policy</h1>
          <div className="page__content">
            <p className="t-epsilon s-bottom--lrg">
              Last updated: June 6th, 2023
            </p>
            <p className="t-epsilon s-bottom--lrg">
              This Privacy Policy describes Our policies and procedures on the
              collection, use and disclosure of Your information when You use
              the Service and tells You about Your privacy rights and how the
              law protects You.
            </p>
            <p className="t-epsilon s-bottom--xlrg">
              We use Your Personal data to provide and improve the Service. By
              using the Service, You agree to the collection and use of
              information in accordance with this Privacy Policy.
            </p>
            <h2 className="t-beta t-primary s-bottom--lrg">
              Interpretation and Definitions
            </h2>
            <h3 className="t-gamma t-primary s-bottom--lrg">Interpretation</h3>
            <p className="t-epsilon s-bottom--xlrg">
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </p>
            <h3 className="t-gamma t-primary s-bottom--lrg">Definitions</h3>
            <p className="t-epsilon s-bottom--lrg">
              For the purposes of this Privacy Policy:
            </p>
            <ul className="privacy-policy__list s-bottom--xlrg">
              <li className="t-epsilon s-bottom--med">
                <strong>You</strong> means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </li>
              <li className="t-epsilon s-bottom--med">
                <strong>Company</strong> (referred to as either "the Company",
                "We", "Us" or "Our" in this Agreement) refers to Orqa d.o.o.,
                J.J. Strossmayera 341 31000 Osijek, Croatia.
              </li>
              <li className="t-epsilon s-bottom--med">
                <strong>Application</strong> means the software program provided
                by the Company downloaded by You on any electronic device, named
                Orqa FPV.Connect
              </li>
              <li className="t-epsilon s-bottom--med">
                <strong>Affiliate</strong> means an entity that controls, is
                controlled by or is under common control with a party, where
                "control" means ownership of 50% or more of the shares, equity
                interest or other securities entitled to vote for election of
                directors or other managing authority.
              </li>
              <li className="t-epsilon s-bottom--med">
                <strong>Account</strong> means a unique account created for You
                to access our Service or parts of our Service.
              </li>
              <li className="t-epsilon s-bottom--med">
                <strong>Service</strong> refers to the Application.
              </li>
              <li className="t-epsilon s-bottom--med">
                <strong>Country</strong> refers to: Croatia
              </li>
              <li className="t-epsilon s-bottom--med">
                <strong>Service Provider</strong> means any natural or legal
                person who processes the data on behalf of the Company. It
                refers to third-party companies or individuals employed by the
                Company to facilitate the Service, to provide the Service on
                behalf of the Company, to perform services related to the
                Service or to assist the Company in analyzing how the Service is
                used.
              </li>
              <li className="t-epsilon s-bottom--med">
                <strong>Third-party Social Media Service</strong> refers to any
                website or any social network website through which a User can
                log in or create an account to use the Service.
              </li>
              <li className="t-epsilon s-bottom--med">
                <strong>Personal Data</strong> is any information that relates
                to an identified or identifiable individual.
              </li>
              <li className="t-epsilon s-bottom--med">
                <strong>Device</strong> means any device that can access the
                Service such as a computer, a cellphone or a digital tablet.
              </li>
              <li className="t-epsilon s-bottom--med">
                <strong>Usage Data</strong> refers to data collected
                automatically, either generated by the use of the Service or
                from the Service infrastructure itself (for example, the
                duration of a page visit).
              </li>
            </ul>
            <h2 className="t-beta t-primary s-bottom--lrg">
              Collecting and Using Your Personal Data
            </h2>
            <h3 className="t-gamma t-primary s-bottom--lrg">
              Types of Data Collected
            </h3>
            <h4 className="t-delta t-primary s-bottom--med">Personal Data</h4>
            <p className="t-epsilon s-bottom--lrg">
              While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or
              identify You. Personally identifiable information may include, but
              is not limited to:
            </p>
            <ul className="privacy-policy__list s-bottom--xlrg">
              <li className="t-epsilon s-bottom--med">Usage Data</li>
            </ul>
            <h4 className="t-delta t-primary s-bottom--med">Usage Data</h4>
            <p className="t-epsilon s-bottom--lrg">
              Usage Data is collected automatically when using the Service.
            </p>
            <p className="t-epsilon s-bottom--lrg">
              Usage Data may include information such as Your Device's Internet
              Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that You visit, the time and date of Your
              visit, the time spent on those pages, unique device identifiers
              and other diagnostic data.
            </p>
            <p className="t-epsilon s-bottom--lrg">
              When You access the Service by or through a mobile device, We may
              collect certain information automatically, including, but not
              limited to, the type of mobile device You use, Your mobile device
              unique ID, the IP address of Your mobile device, Your mobile
              operating system, the type of mobile Internet browser You use,
              unique device identifiers and other diagnostic data.
            </p>
            <p className="t-epsilon s-bottom--lrg">
              We may also collect information that Your browser sends whenever
              You visit our Service or when You access the Service by or through
              a mobile device.
            </p>
            <h4 className="t-delta t-primary s-bottom--med">
              Information Collected while Using the Application
            </h4>
            <p className="t-epsilon s-bottom--lrg">
              While using Our Application, in order to provide features of Our
              Application, We may collect, with your prior permission:
            </p>
            <ul className="privacy-policy__list s-bottom--xlrg">
              <li className="t-epsilon s-bottom--med">
                Information regarding your location
              </li>
            </ul>
            <p className="t-epsilon s-bottom--lrg">
              We use this information to provide features of Our Service, to
              improve and customize Our Service. The information may be uploaded
              to the Company's servers and/or a Service Provider's server or it
              be simply stored on Your device.
            </p>
            <p className="t-epsilon s-bottom--lrg">
              You can enable or disable access to this information at any time,
              through Your Device settings.
            </p>
            <h3 className="t-gamma t-primary s-bottom--lrg">
              Use of Your Personal Data
            </h3>
            <p className="t-epsilon s-bottom--lrg">
              The Company may use Personal Data for the following purposes:
            </p>
            <ul className="privacy-policy__list s-bottom--xlrg">
              <li className="t-epsilon s-bottom--med">
                <strong>To provide and maintain our Service</strong>, including
                to monitor the usage of our Service.
              </li>
              <li className="t-epsilon s-bottom--med">
                <strong>To manage Your Account:</strong> to manage Your
                registration as a user of the Service. The Personal Data You
                provide can give You access to different functionalities of the
                Service that are available to You as a registered user.
              </li>
              <li className="t-epsilon s-bottom--med">
                <strong>For the performance of a contract:</strong> the
                development, compliance and undertaking of the purchase contract
                for the products, items or services You have purchased or of any
                other contract with Us through the Service.
              </li>
              <li className="t-epsilon s-bottom--med">
                <strong>To contact You:</strong> To contact You by email,
                telephone calls, SMS, or other equivalent forms of electronic
                communication, such as a mobile application's push notifications
                regarding updates or informative communications related to the
                functionalities, products or contracted services, including the
                security updates, when necessary or reasonable for their
                implementation.
              </li>
              <li className="t-epsilon s-bottom--med">
                <strong>To provide You</strong> with news, special offers and
                general information about other goods, services and events which
                we offer that are similar to those that you have already
                purchased or enquired about unless You have opted not to receive
                such information.
              </li>
              <li className="t-epsilon s-bottom--med">
                <strong>To manage Your requests:</strong> To attend and manage
                Your requests to Us.
              </li>
            </ul>
            <p className="t-epsilon s-bottom--lrg">
              We may share your personal information in the following
              situations:
            </p>
            <ul className="privacy-policy__list s-bottom--xlrg">
              <li className="t-epsilon s-bottom--med">
                <strong>With Service Providers:</strong> We may share Your
                personal information with Service Providers to monitor and
                analyze the use of our Service, to contact You
              </li>
              <li className="t-epsilon s-bottom--med">
                <strong>For Business transfers:</strong> We may share or
                transfer Your personal information in connection with, or during
                negotiations of, any merger, sale of Company assets, financing,
                or acquisition of all or a portion of our business to another
                company.
              </li>
              <li className="t-epsilon s-bottom--med">
                <strong>With Affiliates:</strong> We may share Your information
                with Our affiliates, in which case we will require those
                affiliates to honor this Privacy Policy. Affiliates include Our
                parent company and any other subsidiaries, joint venture
                partners or other companies that We control or that are under
                common control with Us.
              </li>
              <li className="t-epsilon s-bottom--med">
                <strong>With Business partners:</strong> We may share Your
                information with Our business partners to offer You certain
                products, services or promotions.
              </li>
              <li className="t-epsilon s-bottom--med">
                <strong>With other users:</strong> when You share personal
                information or otherwise interact in the public areas with other
                users, such information may be viewed by all users and may be
                publicly distributed outside. If You interact with other users
                or register through a Third-Party Social Media Service, Your
                contacts on the Third-Party Social Media Service may see Your
                name, profile, pictures and description of Your activity.
                Similarly, other users will be able to view descriptions of Your
                activity, communicate with You and view Your profile.
              </li>
            </ul>
            <h3 className="t-gamma t-primary s-bottom--lrg">
              Retention of Your Personal Data
            </h3>
            <p className="t-epsilon s-bottom--lrg">
              The Company will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use Your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
            </p>
            <p className="t-epsilon s-bottom--lrg">
              The Company will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of Our Service, or We are legally
              obligated to retain this data for longer time periods.
            </p>
            <h3 className="t-gamma t-primary s-bottom--lrg">
              Transfer of Your Personal Data
            </h3>
            <p className="t-epsilon s-bottom--lrg">
              Your information, including Personal Data, is processed at the
              Company's operating offices and in any other places where the
              parties involved in the processing are located. It means that this
              information may be transferred to — and maintained on — computers
              located outside of Your state, province, country or other
              governmental jurisdiction where the data protection laws may
              differ than those from Your jurisdiction.
            </p>
            <p className="t-epsilon s-bottom--lrg">
              Your consent to this Privacy Policy followed by Your submission of
              such information represents Your agreement to that transfer.
            </p>
            <p className="t-epsilon s-bottom--lrg">
              The Company will take all steps reasonably necessary to ensure
              that Your data is treated securely and in accordance with this
              Privacy Policy and no transfer of Your Personal Data will take
              place to an organization or a country unless there are adequate
              controls in place including the security of Your data and other
              personal information.
            </p>
            <h3 className="t-gamma t-primary s-bottom--lrg">
              Disclosure of Your Personal Data
            </h3>
            <h4 className="t-delta t-primary s-bottom--med">
              Business Transactions
            </h4>
            <p className="t-epsilon s-bottom--lrg">
              If the Company is involved in a merger, acquisition or asset sale,
              Your Personal Data may be transferred. We will provide notice
              before Your Personal Data is transferred and becomes subject to a
              different Privacy Policy.
            </p>
            <h4 className="t-delta t-primary s-bottom--med">Law enforcement</h4>
            <p className="t-epsilon s-bottom--lrg">
              Under certain circumstances, the Company may be required to
              disclose Your Personal Data if required to do so by law or in
              response to valid requests by public authorities (e.g. a court or
              a government agency).
            </p>
            <h4 className="t-delta t-primary s-bottom--med">
              Other legal requirements
            </h4>
            <p className="t-epsilon s-bottom--lrg">
              The Company may disclose Your Personal Data in the good faith
              belief that such action is necessary to:
            </p>
            <ul className="privacy-policy__list s-bottom--xlrg">
              <li className="t-epsilon s-bottom--med">
                Comply with a legal obligation
              </li>
              <li className="t-epsilon s-bottom--med">
                Protect and defend the rights or property of the Company
              </li>
              <li className="t-epsilon s-bottom--med">
                Prevent or investigate possible wrongdoing in connection with
                the Service
              </li>
              <li className="t-epsilon s-bottom--med">
                Protect the personal safety of Users of the Service or the
                public
              </li>
              <li className="t-epsilon s-bottom--med">
                Protect against legal liability
              </li>
            </ul>
            <h3 className="t-gamma t-primary s-bottom--lrg">
              Security of Your Personal Data
            </h3>
            <p className="t-epsilon s-bottom--lrg">
              The security of Your Personal Data is important to Us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While We strive to
              use commercially acceptable means to protect Your Personal Data,
              We cannot guarantee its absolute security.
            </p>

            <h4 className="t-delta t-primary s-bottom--med">Data deletion</h4>
            <p className="t-epsilon s-bottom--sml">
              It is your right to completely delete any data we have collected
              on you. Please send an email to{' '}
              <a href="mailto:support@orqafpv.com">support@orqafpv.com</a> to
              request deletion of your data.
            </p>
            <p className="t-epsilon s-bottom--lrg">
              Your continued use of our application will be regarded as
              acceptance of our practices around privacy and personal
              information. If you have any questions about how we handle user
              data and personal information, feel free to contact us.
            </p>

            <h1 className="t-beta t-primary s-bottom--med">
              Your California Privacy Rights (California's Shine the Light law)
            </h1>
            <p className="t-epsilon s-bottom--lrg">
              Under California Civil Code Section 1798 (California's Shine the
              Light law), California residents with an established business
              relationship with us can request information once a year about
              sharing their Personal Data with third parties for the third
              parties' direct marketing purposes.
            </p>
            <p className="t-epsilon s-bottom--lrg">
              If you'd like to request more information under the California
              Shine the Light law, and if you are a California resident, You can
              contact Us using the contact information provided below.
            </p>
            <h1 className="t-beta t-primary s-bottom--med">
              California Privacy Rights for Minor Users (California Business and
              Professions Code Section 22581)
            </h1>
            <p className="t-epsilon s-bottom--lrg">
              California Business and Professions Code section 22581 allow
              California residents under the age of 18 who are registered users
              of online sites, services or applications to request and obtain
              removal of content or information they have publicly posted.
            </p>
            <p className="t-epsilon s-bottom--lrg">
              To request removal of such data, and if you are a California
              resident, You can contact Us using the contact information
              provided below, and include the email address associated with Your
              account.
            </p>
            <p className="t-epsilon s-bottom--lrg">
              Be aware that Your request does not guarantee complete or
              comprehensive removal of content or information posted online and
              that the law may not permit or require removal in certain
              circumstances.
            </p>
            <h1 className="t-beta t-primary s-bottom--med">
              Links to Other Websites
            </h1>
            <p className="t-epsilon s-bottom--lrg">
              Our Service may contain links to other websites that are not
              operated by Us. If You click on a third party link, You will be
              directed to that third party's site. We strongly advise You to
              review the Privacy Policy of every site You visit.
            </p>
            <p className="t-epsilon s-bottom--lrg">
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>
            <h1 className="t-beta t-primary s-bottom--med">
              Changes to this Privacy Policy
            </h1>
            <p className="t-epsilon s-bottom--lrg">
              We may update our Privacy Policy from time to time. We will notify
              You of any changes by posting the new Privacy Policy on this page.
            </p>
            <p className="t-epsilon s-bottom--lrg">
              We will let You know via email and/or a prominent notice on Our
              Service, prior to the change becoming effective and update the
              "Last updated" date at the top of this Privacy Policy.
            </p>
            <p className="t-epsilon s-bottom--lrg">
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
            <h1 className="t-beta t-primary s-bottom--med">Contact Us</h1>
            <p className="t-epsilon s-bottom--lrg">
              If you have any questions about this Privacy Policy, You can
              contact us:
            </p>
            <ul className="privacy-policy__list s-bottom--xlrg">
              <li className="t-epsilon s-bottom--med">
                By email:{' '}
                <a href="mailto:support@orqafpv.com">support@orqafpv.com</a>
              </li>
              <li className="t-epsilon s-bottom--med">
                By visiting this page on our website:{' '}
                <a
                  href="https://orqafpv.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.orqafpv.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};
