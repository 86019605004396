import * as React from 'react';

export class Error404 extends React.Component {
  render() {
    return (
      <section className="error404">
        <div className="wrapper">
          <h1 className="t-beta s-bottom--med">You crashed.</h1>
          <p className="s-bottom--lrg">
            Looks like your drone has crashed on a page that does not exist.
          </p>
          <a href="/" className="btn btn--primary btn--med">
            Fly home
          </a>
        </div>
      </section>
    );
  }
}
