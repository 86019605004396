import * as React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

export const PageNavFPVOne: React.FC = () => {
  const location = useLocation();

  if (location.pathname !== '/fpvone') {
    return null;
  }

  return (
    <nav className="pagenav">
      <ul>
        <li>
          <a href="#Technology">Technology</a>
        </li>
        <li>
          <a href="#Quality">Quality</a>
        </li>
        <li>
          <a href="#MobileApp">Mobile app</a>
        </li>
        <li>
          <a href="#DVR">DVR</a>
        </li>
        <li>
          <a href="#Specifications">Specs</a>
        </li>
      </ul>

      <Link to="/fpvheadsets" className="btn btn--primary btn--tny">
        Buy now
      </Link>
    </nav>
  );
};
