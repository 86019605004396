import React from 'react';

import { SEO } from 'main/components';

export const ESIF: React.FC = () => (
  <>
    <SEO seoTitle="ORQA - ESIF" />
    <section className="projects">
      <header className="projects__header">
        <p className="t-zeta t-upperext  t-nowrap o-40 s-bottom--sml">
          EU Projects
        </p>
        <h1 className="t-beta t-primary t-center s-bottom--med">
          <span className="t-upper">Esif</span> financijski instrumenti
        </h1>
      </header>
      <div className="projects__visual">
        <img
          src={require('main/assets/images/projects-esif.png')}
          alt="ESIF logo"
        />
      </div>
      <section className="wrapper wrapper--ultranarrow">
        <p className="t-delta s-bottom--med">
          HAMAG-BICRO sudjeluje u provedbi financijskog instrumenta
          sufinanciranog iz Europskog fonda za regionalni razvoj u sklopu
          Operativnog programa „Konkurentnost i kohezija”.
        </p>
        <p className="t-delta">
          Orqa d.o.o. - Krajnji primatelj financijskog instrumenta
          sufinanciranog iz Europskog fonda za regionalni razvoj u sklopu
          Operativnog programa „Konkurentnost i kohezija”.
        </p>
      </section>
    </section>
  </>
);
