import React from 'react';

import { FPVVideo } from 'modules/fpv-one-pilot';
import {
  AlexCampbellSpotlight,
  EvanTurnerSpotlight,
  NeilMarekSpotlight,
  OpticsEngine,
  PowerDetails,
  ProductDetails,
  ProductExperience,
  ProductQuality,
  RaceCTA,
  RaceHero,
  RaceNewsletter,
  RaceZoomAnimation,
  ShawnAmesSpotlight,
  TechnicalSpecifications,
  Technology,
} from 'modules/fpv-one-race';
import { SEO } from 'main/components';

export const FPVOneRace: React.FC = () => (
  <>
    <SEO seoTitle="ORQA - FPV.One Race" />
    <section className="fpvrace">
      <RaceHero />
      <Technology />
      <AlexCampbellSpotlight />
      <ProductQuality />
      <ProductDetails />
      <NeilMarekSpotlight />
      <OpticsEngine />
      <ShawnAmesSpotlight />
      <PowerDetails />
      <ProductExperience />
      <TechnicalSpecifications />
      <RaceCTA />
      <RaceZoomAnimation />
      <FPVVideo />
      <EvanTurnerSpotlight />
      <RaceNewsletter />
    </section>
  </>
);
