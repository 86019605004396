import React from 'react';
import { motion } from 'framer-motion';
import InView from 'react-intersection-observer';

import { LazyImage } from 'main/components';

export const ProductPricing: React.FC = () => (
  <InView triggerOnce={false}>
    {({ ref, inView }) => (
      <div className="fpvpilot__cta">
        <LazyImage
          src={require('main/assets/images/fpvonepilot/pilot-concrete.webp')}
          alt="FPV pilot on concrete"
          className="fpvpilot__spotlight__mobile--img"
        />
        <motion.div ref={ref} className="fpvpilot__cta--wrapper">
          <div className="fpvpilot__cta--content">
            <motion.h2
              initial={{ opacity: 0, y: '20px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '20px' }}
              transition={{ ease: 'circOut', duration: 0.3 }}
              className="fpvpilot__cta--title"
            >
              FPV.One Pilot
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '40px' }}
              transition={{ ease: 'circOut', duration: 0.3, delay: 0.4 }}
            >
              <a
                href="https://shop.orqafpv.com/products/orqa-orqa-fpv-one-pilot"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn--primary btn--med"
              >
                Buy now
              </a>
            </motion.p>
          </div>
        </motion.div>
      </div>
    )}
  </InView>
);
