import * as React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import { SEO } from 'main/components';
import { LazyImage } from 'main/components/LazyImage';

interface State {
  loading: boolean;
  isMobile: boolean;
}

export class FPVOne extends React.Component<{}, State> {
  state: State = {
    loading: true,
    isMobile: false,
  };

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const settingsDVR = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <section className="fpvone">
        <SEO
          seoTitle="ORQA - FPV.One"
          seoDescription="Designed for drone racing, drone freestyle, and FPV simulations. Superbly engineered optics set a new benchmark for immersive FPV experience. Beautiful OLED screens, large Field of View, packed with features the pilots have been asking for."
        />
        <section className="fpvone__splash">
          <div className="fpvone__splash__content">
            <div className="wrapper wrapper--narrow">
              <h1 className="t-zeta t-upperext o-40 s-bottom--sml">FPV.One</h1>
              <p className="t-gamma t-primary s-bottom--med">
                Premium Headset We've All Been Dreaming Of&hellip;
              </p>
              <Link to="/fpvheadsets" className="btn btn--primary btn--med">
                Buy now
              </Link>
            </div>
          </div>
          <div className="fpvone__splash__visual">
            <LazyImage
              src={require('main/assets/pages/fpvone/fpvone-bird.webp')}
              alt=""
            />
          </div>
        </section>
        <section className="keyfeatures" id="Technology">
          <div className="wrapper wrapper--wide">
            <header className="keyfeatures__heading">
              <span />
              <h1 className="t-zeta t-upperext">Technology</h1>
            </header>
            <div className="keyfeatures__content">
              <div className="keyfeatures__grid">
                <div className="keyfeatures__grid__item">
                  <div className="keyfeatures__itemcontent">
                    <div className="keyfeatures__itemcontent__heading">
                      <h2 className="t-epsilon s-bottom--sml">
                        Embedded computing power in your goggles.
                      </h2>
                      <div className="keyfeatures__itemcontent__desc">
                        <p className="t-zeta o-60">
                          Most powerful set of FPV goggles available. Its open
                          arhitecture enables expandability and upgrades through
                          two module bays.
                        </p>
                      </div>
                    </div>
                    <div className="keyfeatures__itemcontent__icon">
                      <LazyImage
                        src={require('main/assets/illustration/illustration-5.svg')}
                        alt="Icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="keyfeatures__grid__item">
                  <div className="keyfeatures__itemcontent">
                    <div className="keyfeatures__itemcontent__heading">
                      <h2 className="t-epsilon s-bottom--sml">
                        Built in headtracker, head tilt alarm and auto standby
                      </h2>
                      <div className="keyfeatures__itemcontent__desc">
                        <p className="t-zeta o-60">
                          Preserve your displays and save battery power with
                          built in auto-standby feature. Best antenna
                          performance is garantueed with our Head Tilt Alarm.
                        </p>
                      </div>
                    </div>
                    <div className="keyfeatures__itemcontent__icon">
                      <LazyImage
                        src={require('main/assets/illustration/illustration-7.svg')}
                        alt="Icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="keyfeatures__grid__item">
                  <div className="keyfeatures__itemcontent">
                    <div className="keyfeatures__itemcontent__heading">
                      <h2 className="t-epsilon s-bottom--sml">
                        Support for standard analog and digital video inputs
                      </h2>
                      <div className="keyfeatures__itemcontent__desc">
                        <p className="t-zeta o-60">
                          Choose whether your standard analog VRx, or go digital
                          using micro HDMI video port.
                        </p>
                      </div>
                    </div>
                    <div className="keyfeatures__itemcontent__icon">
                      <LazyImage
                        src={require('main/assets/illustration/illustration-2.svg')}
                        alt="Icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="mobilevisual mobilevisual--1" />
        <section className="keyfeatures" id="Quality">
          <div className="wrapper wrapper--wide">
            <header className="keyfeatures__heading">
              <span />
              <h1 className="t-zeta t-upperext">Quality</h1>
            </header>

            <div className="keyfeatures__content">
              <div className="keyfeatures__grid">
                <div className="keyfeatures__grid__item">
                  <div className="keyfeatures__itemcontent">
                    <div className="keyfeatures__itemcontent__heading">
                      <h2 className="t-epsilon s-bottom--sml">
                        HD image quality from 2x 0.5" Sony OLEDs
                      </h2>
                      <div className="keyfeatures__itemcontent__desc">
                        <p className="t-zeta o-60">
                          Enjoy edge to edge crisp image on best in class OLED
                          displays for FPV headset. Biggest pixel count in the
                          industry.
                        </p>
                      </div>
                    </div>
                    <div className="keyfeatures__itemcontent__icon">
                      <LazyImage
                        src={require('main/assets/illustration/illustration-1.svg')}
                        alt="Icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="keyfeatures__grid__item">
                  <div className="keyfeatures__itemcontent">
                    <div className="keyfeatures__itemcontent__heading">
                      <h2 className="t-epsilon s-bottom--sml">
                        Adjust your FOV and switch aspect ratio.
                      </h2>
                      <div className="keyfeatures__itemcontent__desc">
                        <p className="t-zeta o-60">
                          Drone racing or freestyle? With a click of a button
                          switch from 44° or 33° Field of View and set image to
                          your flying preference.
                        </p>
                      </div>
                    </div>
                    <div className="keyfeatures__itemcontent__icon">
                      <LazyImage
                        src={require('main/assets/illustration/illustration-4.svg')}
                        alt="Icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="keyfeatures__grid__item">
                  <div className="keyfeatures__itemcontent">
                    <div className="keyfeatures__itemcontent__heading">
                      <h2 className="t-epsilon s-bottom--sml">
                        Record your flight in real time with 1280x960 at 60fps
                      </h2>
                      <div className="keyfeatures__itemcontent__desc">
                        <p className="t-zeta o-60">
                          Record your FPV footage in high definition directly
                          from the goggles with built in HD DVR. Forget about
                          lost frames.
                        </p>
                      </div>
                    </div>
                    <div className="keyfeatures__itemcontent__icon">
                      <LazyImage
                        src={require('main/assets/illustration/illustration-3.svg')}
                        alt="Icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="keyfeatures__grid__item">
                  <div className="keyfeatures__itemcontent">
                    <div className="keyfeatures__itemcontent__heading">
                      <h2 className="t-epsilon s-bottom--sml">
                        FPV headset Designed and Manufactured in Europe
                      </h2>
                      <div className="keyfeatures__itemcontent__desc">
                        <p className="t-zeta o-60">
                          Every unit is hand assembled and QC'd by our team of
                          engineers and technicans. Orqa is proudly designed and
                          made in Croatia, Europe.
                        </p>
                      </div>
                    </div>
                    <div className="keyfeatures__itemcontent__icon">
                      <LazyImage
                        src={require('main/assets/illustration/illustration-9.svg')}
                        alt="Icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="featuresection" id="MobileApp">
          <div className="wrapper wrapper--wide">
            <header className="featuresection__header">
              <div className="featuresection__header__left">
                <h2 className="t-beta t-primary">FPV.Connect</h2>
              </div>
              <div className="featuresection__header__right">
                <p className="t-delta o-80">
                  Use the mobile app to download flight videos and update
                  headset firmware
                </p>
                <Link
                  to="/fpvconnect"
                  className="btn btn--primary btn--med s-top--lrg"
                >
                  Connect
                </Link>
              </div>
            </header>
            <LazyImage
              src={require('main/assets/pages/fpvone/fpvone-mobile.webp')}
              alt=""
              className="featuresection__visual1"
            />
            <div className="featuresection__header featuresection__header--nomargo">
              <div className="featuresection__header__left">
                <h2 className="t-delta s-bottom--med">
                  Auto firmware updates for your goggles.
                </h2>
              </div>
              <div className="featuresection__header__right">
                <p className="t-epsilon o-80 s-bottom--med">
                  Say “Hello”! to auto firmware updates of your goggles and
                  access your flight content straight from mobile phone. Go live
                  or broadcast directly from the goggles.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="featuresection" id="Design">
          <div className="wrapper wrapper--wide">
            <header className="featuresection__header">
              <div className="featuresection__header__left">
                <h2 className="t-beta t-primary">Design</h2>
              </div>
              <div className="featuresection__header__right">
                <p className="t-delta o-80">
                  Compact and ergonomic design with no light leakage. Designed
                  for pilots from pilots. Best ergonomics, light weight and
                  highest end materials in FPV world.
                </p>
              </div>
            </header>
            <Slider {...settings} className="s-bottom--xlrg">
              <div>
                <LazyImage
                  src={require('main/assets/pages/fpvone/fpvone-design-1.webp')}
                  alt=""
                  className="featuresection__visual1"
                />
              </div>
              <div>
                <LazyImage
                  src={require('main/assets/pages/fpvone/fpvone-design-2.webp')}
                  alt=""
                  className="featuresection__visual1"
                />
              </div>
              <div>
                <LazyImage
                  src={require('main/assets/pages/fpvone/fpvone-design-3.webp')}
                  alt=""
                  className="featuresection__visual1"
                />
              </div>
              <div>
                <LazyImage
                  src={require('main/assets/pages/fpvone/fpvone-design-4.webp')}
                  alt=""
                  className="featuresection__visual1"
                />
              </div>
              <div>
                <LazyImage
                  src={require('main/assets/pages/fpvone/fpvone-design-5.webp')}
                  alt=""
                  className="featuresection__visual1"
                />
              </div>
            </Slider>
            <div className="narrowblock">
              <div className="narrowblock__content">
                <p className="t-gamma o-80 s-bottom--med">
                  Orqa combines functionality with modern aesthetics by an
                  artistically implemented visual and tactile construct into a
                  sophistically engineered software and hardware system.
                </p>
              </div>
            </div>
            <div className="s-bottom--lrg">
              <video controls className="fpvone__video">
                <source
                  src={require('main/assets/video/orqa-3d.webm')}
                  type="video/webm"
                />
                <source
                  src={require('main/assets/video/orqa-3d.mp4')}
                  type="video/mp4"
                />
                Your browser does not support the video element. Kindly update
                it to latest version.
              </video>
            </div>
            <div className="narrowblock">
              <div className="narrowblock__content">
                <p className="t-epsilon o-80 s-bottom--med">
                  Following the ergonomy and user experience are key factors,
                  FPV One goggles truly (re)presents a way of handling such a
                  device can be uplifted by the sleek modern feeling. Sharp
                  folds emerging into a pleasing set of triangular surfaces
                  ensure the complexity of geometrical expression does not leave
                  remaining nor feeling unnoticed.
                </p>
                <p className="t-epsilon o-80">
                  The idea of the design was the dystopian apocalypse - a
                  product that represents the future in combining cultural
                  perception of humanity hereafter.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="featuresection" id="DVR">
          <div className="wrapper wrapper--wide">
            <header className="featuresection__header">
              <div className="featuresection__header__left">
                <h2 className="t-beta t-primary">DVR</h2>
              </div>
              <div className="featuresection__header__right">
                <p className="t-delta o-80">
                  Check out DVR videos by our pilots.
                </p>
              </div>
            </header>
            <div className="dvrlist">
              <Slider {...settingsDVR} className="s-bottom--lrg">
                <div className="dvrlist__item">
                  <iframe
                    src="https://streamable.com/e/xnx3qr?loop=0"
                    frameBorder="0"
                    allowFullScreen
                    title="DVR 1"
                  />
                </div>
                <div className="dvrlist__item">
                  <iframe
                    src="https://streamable.com/e/f3qj00?loop=0"
                    frameBorder="0"
                    allowFullScreen
                    title="DVR 2"
                  />
                </div>
                <div className="dvrlist__item">
                  <iframe
                    src="https://streamable.com/e/tkqw82?loop=0"
                    frameBorder="0"
                    allowFullScreen
                    title="DVR 3"
                  />
                </div>
                <div className="dvrlist__item">
                  <iframe
                    src="https://streamable.com/e/fgh06m?loop=0"
                    frameBorder="0"
                    allowFullScreen
                    title="DVR 4"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </section>
        <section className="specs" id="Specifications">
          <header className="specs__heading">
            <div className="wrapper">
              <h1 className="t-zeta t-upperext">Tech</h1>
            </div>
          </header>
          <div className="wrapper">
            <div className="specs__list">
              <div className="specs__list__item">
                <div className="specs__list__item__icon">
                  <LazyImage
                    src={require('main/assets/icons/icon-viewing-angle.svg')}
                    alt="Viewing angle"
                  />
                </div>
                <h2 className="specs__list__item__title t-zeta">
                  Viewing angle
                </h2>
                <p className="specs__list__item__desc t-zeta">44° or 33°</p>
              </div>

              <div className="specs__list__item">
                <div className="specs__list__item__icon">
                  <LazyImage
                    src={require('main/assets/icons/icon-resolution.svg')}
                    alt="Screen resolution"
                  />
                </div>
                <h2 className="specs__list__item__title t-zeta">
                  Screen resolution
                </h2>
                <p className="specs__list__item__desc t-zeta">
                  1280 x 960 pixels
                </p>
              </div>

              <div className="specs__list__item">
                <div className="specs__list__item__icon">
                  <LazyImage
                    src={require('main/assets/icons/icon-aspect-ratio.svg')}
                    alt="Aspect ratio"
                  />
                </div>
                <h2 className="specs__list__item__title t-zeta">
                  Aspect ratio
                </h2>
                <p className="specs__list__item__desc t-zeta">
                  4:3 native or 16:9 (720p)
                </p>
              </div>

              <div className="specs__list__item">
                <div className="specs__list__item__icon">
                  <LazyImage
                    src={require('main/assets/icons/icon-power-consumption.svg')}
                    alt="Power supply"
                  />
                </div>
                <h2 className="specs__list__item__title t-zeta">
                  Power supply
                </h2>
                <p className="specs__list__item__desc t-zeta">
                  6-10VDC (2S LiPo Battery)
                </p>
              </div>

              <div className="specs__list__item">
                <div className="specs__list__item__icon">
                  <LazyImage
                    src={require('main/assets/icons/icon-power-consumption.svg')}
                    alt="Power consumption"
                  />
                </div>
                <h2 className="specs__list__item__title t-zeta">
                  Power consumption
                </h2>
                <p className="specs__list__item__desc t-zeta">
                  3.3W (typical), 4.2W (max)
                </p>
              </div>

              <div className="specs__list__item">
                <div className="specs__list__item__icon">
                  <LazyImage
                    src={require('main/assets/icons/icon-head-tracker.svg')}
                    alt="Integrated Head Tracker Output"
                  />
                </div>
                <h2 className="specs__list__item__title t-zeta">
                  Integrated Head Tracker Output
                </h2>
                <p className="specs__list__item__desc t-zeta">3.5mm jack</p>
              </div>

              <div className="specs__list__item">
                <div className="specs__list__item__icon">
                  <LazyImage
                    src={require('main/assets/icons/icon-hdmi.svg')}
                    alt="HDMI video input"
                  />
                </div>
                <h2 className="specs__list__item__title t-zeta">
                  HDMI video input
                </h2>
                <p className="specs__list__item__desc t-zeta">micro HDMI</p>
              </div>

              <div className="specs__list__item">
                <div className="specs__list__item__icon">
                  <LazyImage
                    src={require('main/assets/icons/icon-battery.svg')}
                    alt="Battery connector"
                  />
                </div>
                <h2 className="specs__list__item__title t-zeta">
                  Battery connector
                </h2>
                <p className="specs__list__item__desc t-zeta">
                  2.1 x 5.5mm barrel connector
                </p>
              </div>

              <div className="specs__list__item">
                <div className="specs__list__item__icon">
                  <LazyImage
                    src={require('main/assets/icons/icon-ipd.svg')}
                    alt="IPD Range"
                  />
                </div>
                <h2 className="specs__list__item__title t-zeta">IPD Range</h2>
                <p className="specs__list__item__desc t-zeta">56 - 74 mm</p>
              </div>

              <div className="specs__list__item">
                <div className="specs__list__item__icon">
                  <LazyImage
                    src={require('main/assets/icons/icon-dvr.svg')}
                    alt="Integrated DVR"
                  />
                </div>
                <h2 className="specs__list__item__title t-zeta">
                  Integrated DVR
                </h2>
                <p className="specs__list__item__desc t-zeta">
                  1280x960 25-60 fps, H.264, 18mbps .mp4
                </p>
              </div>

              {/* <div className="specs__list__item">
                <div className="specs__list__item__icon">
                  <LazyImage
                    src={require('main/assets/icons/icon-strap.svg')}
                    alt="Wide head strap"
                  />
                </div>
                <h2 className="specs__list__item__title t-zeta">
                  Wide head strap
                </h2>
                <p className="specs__list__item__desc t-zeta">35mm</p>
              </div> */}

              <div className="specs__list__item">
                <div className="specs__list__item__icon">
                  <LazyImage
                    src={require('main/assets/icons/icon-reciever.svg')}
                    alt="Standard receiver bay"
                  />
                </div>
                <h2 className="specs__list__item__title t-zeta">
                  Standard receiver bay
                </h2>
                <p className="specs__list__item__desc t-zeta">High power</p>
              </div>

              <div className="specs__list__item">
                <div className="specs__list__item__icon">
                  <LazyImage
                    src={require('main/assets/icons/icon-secondary-bay.svg')}
                    alt="Secondary bay for additional modules"
                  />
                </div>
                <h2 className="specs__list__item__title t-zeta">
                  Secondary bay for additional modules
                </h2>
                <p className="specs__list__item__desc t-zeta">
                  3.3V, SDIO, UART
                </p>
              </div>

              <div className="specs__list__item">
                <div className="specs__list__item__icon">
                  <LazyImage
                    src={require('main/assets/icons/icon-weight.svg')}
                    alt="Weight (without battery)"
                  />
                </div>
                <h2 className="specs__list__item__title t-zeta">
                  Weight (without battery)
                </h2>
                <p className="specs__list__item__desc t-zeta">232 grams</p>
              </div>

              <div className="specs__list__item">
                <div className="specs__list__item__icon">
                  <LazyImage
                    src={require('main/assets/icons/icon-dimensions.svg')}
                    alt="Dimensions"
                  />
                </div>
                <h2 className="specs__list__item__title t-zeta">Dimensions</h2>
                <p className="specs__list__item__desc t-zeta">
                  177 x 99 x 72 mm
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}
