import React from 'react';

interface Props {
  title: string;
  description: string;
}

export const FPVPilotListItem: React.FC<Props> = ({ title, description }) => (
  <div className="fpvpilot__list--item">
    <h3 className="fpvpilot__list--title">{title}</h3>
    <p className="fpvpilot__list--description">{description}</p>
  </div>
);
