import * as React from 'react';

export class Register extends React.Component {
  render() {
    return (
      <section className="page">
        <div className="wrapper wrapper--narrow">
          <h1 className="t-beta s-bottom--med">Register</h1>
          <div className="page__content">
            <p className="t-delta s-bottom--xlrg">
              Register your ORQA FPV.One device.
            </p>
            <form
              action="https://orqafpv.us18.list-manage.com/subscribe/post?u=f528df63a6bb5a1820706dad8&amp;id=e9af3a86fe"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
              noValidate
            >
              <div className="field">
                <label htmlFor="customerEmail" className="field__lbl">
                  Email Address
                </label>
                <input
                  type="email"
                  name="EMAIL"
                  className="input input--med input---text"
                  placeholder="Enter your e-mail address"
                  id="customerEmail"
                />
              </div>

              <div className="field">
                <label htmlFor="customerFirstName" className="field__lbl">
                  First Name{' '}
                </label>
                <input
                  type="text"
                  name="FNAME"
                  className="input input--med input---text"
                  placeholder="Enter your first name"
                  id="customerFirstName"
                />
              </div>
              <div className="field">
                <label htmlFor="customerLastName" className="field__lbl">
                  Last Name
                </label>
                <input
                  type="text"
                  name="LNAME"
                  className="input input--med input---text"
                  placeholder="Enter your last name"
                  id="customerLastName"
                />
              </div>

              <div className="field">
                <label htmlFor="customerGogglesID" className="field__lbl">
                  FPV.One goggles ID
                </label>
                <input
                  type="text"
                  name="MMERGE3"
                  className="input input--med input---text"
                  placeholder="Enter your FPV.One goggles ID"
                  id="customerGogglesID"
                />
              </div>

              <div id="mce-responses">
                <div
                  className="response"
                  id="mce-error-response"
                  style={{ display: 'none' }}
                />
                <div
                  className="response"
                  id="mce-success-response"
                  style={{ display: 'none' }}
                />
              </div>
              <div
                style={{ position: 'absolute', left: '-10000px' }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_f528df63a6bb5a1820706dad8_e9af3a86fe"
                  tabIndex={-1}
                  value=""
                />
              </div>
              <div className="s-top--xlrg">
                <button
                  type="submit"
                  value="Subscribe"
                  name="subscribe"
                  id="embedded-subscribe"
                  className="btn btn--primary btn--med"
                >
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}
