import React from 'react';
import { motion } from 'framer-motion';
import InView from 'react-intersection-observer';

import { FPVPilotListItem } from 'main/components';

export const Measurements: React.FC = () => (
  <InView triggerOnce>
    {({ ref, inView }) => (
      <section className="fpvonepilot__visual--experience">
        <div className="fpvpilot__list--wrapper">
          <motion.img
            initial={{ opacity: 0 }}
            animate={{
              opacity: inView ? 1 : 0,
            }}
            transition={{
              ease: 'easeOut',
              delay: 0.4,
              duration: 0.6,
            }}
            src={require('main/assets/images/fpvonepilot/ORQA_MKII_side_view.webp')}
            alt="FPV PILOT visual experience"
            className="fpvpilot__specifications__image--desktop"
          />
          <div className="fpvpilot__list__column--wrapper t-left">
            <motion.h3
              initial={{ opacity: 0, y: '20px' }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : '20px',
              }}
              transition={{
                ease: 'easeOut',
                duration: 0.4,
              }}
              className="fpvonepilot__visual__title--secondary t-upper"
            >
              Measurements
            </motion.h3>
            <motion.h2
              ref={ref}
              initial={{ opacity: 0, y: '30px' }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : '30px',
              }}
              transition={{
                ease: 'easeOut',
                delay: 0.2,
                duration: 0.4,
              }}
              className="fpvpilot__collumn--title t-upper"
            >
              Perfectly fitted.
            </motion.h2>
            <div className="fpvpilot__collumn-wrapper fpvpilot__list__row--template-2">
              <motion.div
                initial={{ opacity: 0, y: '40px' }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : '40px',
                }}
                transition={{
                  ease: 'easeOut',
                  delay: 0.2,
                  duration: 0.4,
                }}
                className="fpvpilot__column--left fpvpilot__list__row--1"
              >
                <FPVPilotListItem
                  title="177 x 107 x 72mm"
                  description="Dimensions"
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: '40px' }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : '40px',
                }}
                transition={{
                  ease: 'easeOut',
                  delay: 0.4,
                  duration: 0.4,
                }}
                className="fpvpilot__column--left fpvpilot__list__row--2"
              >
                <FPVPilotListItem
                  title="3.5mm jack"
                  description="Integrated Head Tracker Output"
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: '40px' }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : '40px',
                }}
                transition={{
                  ease: 'easeOut',
                  delay: 0.6,
                  duration: 0.4,
                }}
                className="fpvpilot__column--right fpvpilot__list__row--"
              >
                <FPVPilotListItem
                  title="256g"
                  description="Weight (without battery)"
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: '40px' }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : '40px',
                }}
                transition={{
                  ease: 'easeOut',
                  delay: 0.8,
                  duration: 0.4,
                }}
                className="fpvpilot__column--right fpvpilot__list__row--2"
              >
                <FPVPilotListItem title="56-74 mm" description="IPD Range" />
              </motion.div>
            </div>
          </div>
        </div>
        <img
          src={require('main/assets/images/fpvonepilot/ORQA_MKII_side_view.webp')}
          alt="FPV PILOT visual experience"
          className="fpvpilot__specifications__image--mobile"
        />
      </section>
    )}
  </InView>
);
