import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { useParams, Link } from 'react-router-dom';
import { RichText } from 'prismic-reactjs';

import { GET_PRODUCTS } from 'main/graphql';
import { LazyImage, LoadingSpinner, SEO } from 'main/components';
import { ProductItem } from 'modules/products';

interface Params {
  slug: string;
}

type Props = Params;

export const Products: React.FC<Props> = () => {
  const { slug } = useParams();
  const { loading, data, error } = useQuery(GET_PRODUCTS);
  const [allProducts, setAllProducts] = useState<Array<ProductItem>>([]);
  const [currentProduct, setCurrentProduct] = useState<ProductItem>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(onMount, [data]);

  useEffect(() => {
    if (!slug) return;
    setCurrentProduct(allProducts.find(product => product.slug === slug));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  return (
    <>
      <SEO seoTitle="ORQA - Products" />
      <section className="page">
        <div className="blog">
          <aside className="blog__categories">
            <nav className="blog__sidenav">
              <p className="t-secondary t-upperext t-delta">Products</p>
              <ul>
                {allProducts.map((product: ProductItem, index: number) => (
                  <li key={index}>
                    <Link to={product.slug}>{product.title}</Link>
                  </li>
                ))}
              </ul>
            </nav>
          </aside>
          <section className="blog__content">
            <div className="page__content">
              {loading || isLoading ? (
                <div className="blog__spinner">
                  <LoadingSpinner />
                </div>
              ) : (
                <>
                  {error && <p className="blog__error">Error...</p>}
                  {currentProduct && (
                    <>
                      <LazyImage
                        src={currentProduct.featuredImage}
                        className="hero-image s-bottom--lrg"
                        alt=""
                      />
                      <h1 className="t-beta s-bottom--xlrg">
                        {currentProduct.title}
                      </h1>
                      <div className="s-left--med blog-details">
                        <RichText render={currentProduct.shortDescription} />
                      </div>
                      <div className="s-left--med blog-details">
                        <RichText render={currentProduct.longDescription} />
                      </div>
                    </>
                  )}
                  {!currentProduct && !error && (
                    <p className="blog__error">No product to show...</p>
                  )}
                </>
              )}
            </div>
          </section>
        </div>
      </section>
    </>
  );

  function onMount() {
    if (!loading) {
      destructureBlogPosts(data, []);
    }
  }

  function destructureBlogPosts(data: any, products: ProductItem[]) {
    setIsLoading(true);
    if (!data || !data.allProducts || data.allProducts.edges.length === 0) {
      setIsLoading(false);
      return;
    }

    data.allProducts.edges.forEach((product: any) => {
      const productItem: ProductItem = {
        title: product.node.product_title[0].text,
        slug: product.node._meta.uid,
        shortDescription: product.node.short_product_description,
        longDescription: product.node.long_product_description,
        featuredImage: product.node.featured_image.url,
      };
      products.push(productItem);
    });

    setAllProducts(products);
    if (!slug) {
      setCurrentProduct(products[0]);
      setIsLoading(false);
      return;
    }
    setCurrentProduct(products.find(product => product.slug === slug));
    setIsLoading(false);
  }
};
