enum types {
  isChanging = 'cart/IS_CHANGING',
  Open = 'cart/OPEN',
  Close = 'cart/CLOSE',
  CreateClient = 'cart/CREATE_CLIENT',
  CreateCheckout = 'cart/CREATE_CHECKOUT',
  AddItem = 'cart/ADD_ITEM',
  RemoveItem = 'cart/REMOVE_ITEM',
}

export const CartTypes = {
  ...types,
};
