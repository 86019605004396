import React from 'react';
import { motion } from 'framer-motion';
import InView from 'react-intersection-observer';

export const ProductQuality: React.FC = () => (
  <InView triggerOnce>
    {({ ref, inView }) => (
      <section ref={ref} className="fpvrace__content--wrapper">
        <motion.p
          initial={{ opacity: 0, y: '20px' }}
          animate={{
            opacity: inView ? 1 : 0,
            y: inView ? 0 : '20px',
          }}
          transition={{
            ease: 'easeOut',
            duration: 0.4,
          }}
          className="fpvpilot__content__title--secondary t-upper"
        >
          Image quality
        </motion.p>
        <motion.h2
          initial={{ opacity: 0, y: '30px' }}
          animate={{
            opacity: inView ? 1 : 0,
            y: inView ? 0 : '30px',
          }}
          transition={{
            ease: 'easeOut',
            delay: 0.2,
            duration: 0.4,
          }}
          className="fpvpilot__content--title t-upper"
        >
          The path ahead is clear
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: '40px' }}
          animate={{
            opacity: inView ? 1 : 0,
            y: inView ? 0 : '40px',
          }}
          transition={{
            ease: 'easeOut',
            delay: 0.3,
            duration: 0.4,
          }}
          className="fpvpilot__content--description"
        >
          Enjoy the thrill of the race through high-end OLED displays.
        </motion.p>
        <div className="fpvpilot__content__image--gradient">
          <motion.img
            initial={{ opacity: 0 }}
            animate={{
              opacity: inView ? 1 : 0,
            }}
            transition={{
              ease: 'easeOut',
              delay: 0.4,
              duration: 0.4,
            }}
            src={require('main/assets/images/fpvonerace/ORQA_MKII_back-view.webp')}
            alt="FPV Pilot top view"
            className=""
          />
        </div>
      </section>
    )}
  </InView>
);
