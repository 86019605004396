import React from 'react';
import { motion } from 'framer-motion';
import InView from 'react-intersection-observer';

import { FPVPilotListItem } from 'main/components';

export const VisualExperience: React.FC = () => (
  <InView triggerOnce>
    {({ ref, inView }) => (
      <section className="fpvonepilot__visual--experience fpvonepilot__visual--primary">
        <motion.h3
          initial={{ opacity: 0, y: '20px' }}
          animate={{
            opacity: inView ? 1 : 0,
            y: inView ? 0 : '20px',
          }}
          transition={{
            ease: 'easeOut',
            duration: 0.4,
          }}
          className="fpvonepilot__visual__title--secondary t-center t-upper"
        >
          New optics engine
        </motion.h3>
        <motion.h2
          initial={{ opacity: 0, y: '30px' }}
          animate={{
            opacity: inView ? 1 : 0,
            y: inView ? 0 : '30px',
          }}
          transition={{
            ease: 'easeOut',
            delay: 0.2,
            duration: 0.4,
          }}
          className="fpvonepilot__visual--title t-center t-upper"
        >
          Adapt visual experience to your eyes.
        </motion.h2>
        <motion.p
          ref={ref}
          initial={{ opacity: 0, y: '40px' }}
          animate={{
            opacity: inView ? 1 : 0,
            y: inView ? 0 : '40px',
          }}
          transition={{
            ease: 'easeOut',
            delay: 0.3,
            duration: 0.4,
          }}
          className="fpvonepilot__visual--description t-center"
        >
          New optics engine brings unprecedented fine-tuning capabilities.
        </motion.p>
        <div className="fpvpilot__list--wrapper fpvpilot__list__row--template-3">
          <motion.div
            initial={{ opacity: 0, y: '40px' }}
            animate={{
              opacity: inView ? 1 : 0,
              y: inView ? 0 : '40px',
            }}
            transition={{
              ease: 'easeOut',
              delay: 0.2,
              duration: 0.4,
            }}
            className="fpvpilot__list__column--left fpvpilot__list__row--1"
          >
            <FPVPilotListItem
              title="1280 x 960 pixels"
              description="Screen resolution"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: '40px' }}
            animate={{
              opacity: inView ? 1 : 0,
              y: inView ? 0 : '40px',
            }}
            transition={{
              ease: 'easeOut',
              delay: 0.4,
              duration: 0.4,
            }}
            className="fpvpilot__list__column--left fpvpilot__list__row--2"
          >
            <FPVPilotListItem
              title="4:3 Native or 16:9 (720p)"
              description="Arspect ratio"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: '40px' }}
            animate={{
              opacity: inView ? 1 : 0,
              y: inView ? 0 : '40px',
            }}
            transition={{
              ease: 'easeOut',
              delay: 0.6,
              duration: 0.4,
            }}
            className="fpvpilot__list__column--left fpvpilot__list__row--3"
          >
            <FPVPilotListItem
              title="1280x960, 50/60fps, H.264, mp4"
              description="Integrated DVR"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: '40px' }}
            animate={{
              opacity: inView ? 1 : 0,
              y: inView ? 0 : '40px',
            }}
            transition={{
              ease: 'easeOut',
              delay: 0.8,
              duration: 0.4,
            }}
            className="fpvpilot__list__column--right fpvpilot__list__row--1"
          >
            <FPVPilotListItem title="37°" description="Viewing angle" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: '40px' }}
            animate={{
              opacity: inView ? 1 : 0,
              y: inView ? 0 : '40px',
            }}
            transition={{
              ease: 'easeOut',
              delay: 1,
              duration: 0.4,
            }}
            className="fpvpilot__list__column--right fpvpilot__list__row--2"
          >
            <FPVPilotListItem title="-4D - +4D" description="Focus adjust" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: '40px' }}
            animate={{
              opacity: inView ? 1 : 0,
              y: inView ? 0 : '40px',
            }}
            transition={{
              ease: 'easeOut',
              delay: 1.2,
              duration: 0.4,
            }}
            className="fpvpilot__list__column--right fpvpilot__list__row--3"
          >
            <FPVPilotListItem
              title="Micro HDMI"
              description="HDMI video input"
            />
          </motion.div>
        </div>
        <div className="fpvpilot__list--gradient">
          <motion.img
            initial={{ opacity: 0 }}
            animate={{
              opacity: inView ? 1 : 0,
            }}
            transition={{
              ease: 'easeOut',
              delay: 0.4,
              duration: 0.6,
            }}
            src={require('main/assets/images/fpvonepilot/ORQA_MKII_visual_experience.webp')}
            alt="FPV PILOT visual experience"
            className="fpvpilot__list__image--desktop"
          />
          <motion.img
            initial={{ opacity: 0 }}
            animate={{
              opacity: inView ? 1 : 0,
            }}
            transition={{
              ease: 'easeOut',
              delay: 0.4,
              duration: 0.6,
            }}
            src={require('main/assets/images/fpvonepilot/ORQA_MKII_Visual_exp.webp')}
            alt="FPV PILOT visual experience"
            className="fpvpilot__list__image--mobile"
          />
        </div>
      </section>
    )}
  </InView>
);
