import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk, { ThunkDispatch } from 'redux-thunk';

import { cartReducer } from 'modules/cart';

import { ApplicationState } from '../models';

export const configureStore = () => {
  /**
   * Create the composing function for our middlewares
   * Include dev tools support
   */
  const composeEnhancers = composeWithDevTools({});

  /**
   * Merge all reducers into a single object
   */
  const rootReducer = {
    cart: cartReducer,
  };

  /**
   * We'll create our store with the combined reducers and all enchancers
   */
  const store = createStore(
    combineReducers(rootReducer),
    {},
    composeEnhancers(applyMiddleware(thunk)),
  );

  return store;
};

export type AppDispatch = ThunkDispatch<ApplicationState, any, AnyAction>;
