import React from 'react';

import { SEO } from 'main/components';

export const BrincDronesSimulator = () => (
  <>
    <SEO seoTitle="ORQA - Brinc Drones Simulator" />
    <section className="page">
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSfKMun-N3_awR26wqNQAw1u74pvt60siTc_izymxf4TQP5fMg/viewform"
        title="Fill out the form to get your license key for the simulator."
        width="100%"
        height="1500px"
        frameBorder="0"
      />
    </section>
  </>
);
