import * as React from 'react';

import { SEO } from 'main/components';

interface State {
  loading: boolean;
  isMobile: boolean;
}

export class CompanyCulture extends React.Component<{}, State> {
  render() {
    return (
      <>
        <SEO seoTitle="ORQA - Company Culture" />
        <section className="companyculture">
          <section className="splash">
            <div className="splash__content">
              <div className="wrapper wrapper--narrow">
                <h1 className="t-zeta t-upperext o-40 s-bottom--sml">
                  Company
                </h1>
                <p className="t-gamma t-primary s-bottom--med">Culture</p>
              </div>
            </div>
          </section>
          <div className="wrapper wrapper--ultranarrow">
            <p className="t-gamma s-bottom--xlrg">
              Orqa is a company whose mission is to become the World’s number
              one technology provider for First Person View (FPV) as well as the
              advanced Remote Reality (RR) applications, and innovation lies at
              its very core.
            </p>
            <p className="t-epsilon s-bottom--sml">
              The company’s innovation-oriented mindset comes from the
              uninhibitedness of its young, creative team, with the average age
              of only 26. For the majority of such young people, working at Orqa
              serves as their very first working experience, giving them a
              chance to work and learn in a dynamic environment, but also giving
              us a chance to enrich our team with a brand-new way of thinking.
            </p>
            <p className="t-epsilon s-bottom--sml">
              Gradually, we build our company based on our roles, ranging from
              engineers, physicists, and assembly technicians to sales and
              marketing specialists, but also on our tendency to build a unique,
              well-rounded product with the combined effort of young
              professionals with various skills and perspectives utilized
              towards a common goal.
            </p>
            <p className="t-epsilon">
              Ultimately, even though we aim high, we always keep a humble
              mindset by valuing the community feedback and wishes, which, in
              return, helps us to grow and improve.
            </p>
          </div>
        </section>
      </>
    );
  }
}
