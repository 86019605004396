import React from 'react';

import { LazyImage } from 'main/components';

import { PilotItemSocial } from './PilotItemSocial';

import { Pilot } from '../models';

interface Props {
  pilot: Pilot;
}

export const PilotItem: React.FC<Props> = ({ pilot }) => {
  const socials = Object.entries(pilot.socials);

  return (
    <div key={pilot.handle} className="pilot-item">
      <div className="pilot-item__img">
        <LazyImage
          src={require(`main/assets/images/pilots/${pilot.imgSrc}`)}
          alt={`${pilot.name} avatar`}
        />
      </div>
      <div>
        <p className="t-upper t-gamma s-bottom--tny">{pilot.name}</p>
        <p className="t-delta s-bottom-tny">{pilot.handle}</p>
        <p className="pilot-item__country t-zeta">{pilot.country}</p>
        <div className="pilot-item__socials">
          {socials.map(([icon, link]) => (
            <PilotItemSocial key={icon} icon={icon} link={link} />
          ))}
        </div>
      </div>
    </div>
  );
};
