import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import Slider from 'react-slick';
import Select, { ValueType } from 'react-select';

import { ApplicationState, AppDispatch } from 'modules/redux-store';
import { CartThunks } from 'modules/cart';
import { LazyImage, LoadingSpinner, SEO } from 'main/components';

interface State {
  loading?: boolean;
  error?: string;
}

export const FPVDetails: React.FC = () => {
  const [state, setState] = useState<State>({
    loading: true,
    error: undefined,
  });
  const { id } = useParams();
  const [item, setItem] = useState<ShopifyBuy.Product>();
  const { client } = useSelector((state: ApplicationState) => state.cart);
  const [chosenVariant, setVariant] = useState<ShopifyBuy.ProductVariant>();

  const dispatch = useDispatch<AppDispatch>();

  const handleVariantChange = (
    variant: ValueType<ShopifyBuy.ProductVariant>,
  ) => {
    setVariant(variant as ShopifyBuy.ProductVariant);
  };

  const variantOptions = item?.variants.map(option => {
    return {
      ...option,
      value: option.id,
      label: option.title,
    };
  });

  const fetchItems = useCallback(
    async (client?: ShopifyBuy.Client) => {
      if (!client) return;

      try {
        const result = await client.product.fetch(id);
        setItem(result);
        setState({ loading: false });
      } catch (error) {
        /**
         * TODO: pass real error value
         */
        setState({ loading: false, error: 'Error...' });
      }
    },
    [id],
  );

  function shopifyImgTypeGuard(image: ShopifyBuy.Image) {
    if ('altText' in image) {
      return image['altText'];
    }

    return '';
  }

  useEffect(() => {
    fetchItems(client);
  }, [client, fetchItems]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const priceCheck = () => {
    return variantOptions && variantOptions.length < 2
      ? item?.variants.map(i => i.price)
      : !chosenVariant?.price
      ? '00.00'
      : chosenVariant?.price;
  };

  const idCheck = () => {
    return variantOptions && variantOptions.length > 1
      ? chosenVariant?.id
      : item?.variants.map(i => i.id);
  };

  const { error } = state;
  return (
    <>
      <SEO seoTitle={`ORQA - ${item?.title || 'Never stop flying'}`} />
      <section className="productdetails">
        <header className="productdetails__heading">
          <div className="wrapper wrapper--wide">
            <h1 className="t-beta">{item?.title}</h1>
          </div>
        </header>{' '}
        {state.loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {error ? (
              <p className="t-center">{error}</p>
            ) : (
              <div className="productdetails__content">
                <div className="wrapper wrapper--wide">
                  <div className="productdetails__layout">
                    <div className="productdetails__gallery">
                      <Slider {...settings} className="s-bottom--xlrg">
                        {item?.images.map((image: ShopifyBuy.Image) => (
                          <div key={image.id}>
                            <LazyImage
                              src="{image.src}"
                              alt={shopifyImgTypeGuard(image)}
                            />
                          </div>
                        ))}
                      </Slider>
                    </div>
                    <div className="productdetails__info t-negative">
                      <div className="s-bottom--lrg">
                        {variantOptions && variantOptions.length > 1 && (
                          <>
                            <p className="t-tiny t-upperext o-60 s-bottom--sml">
                              Select a product type
                            </p>
                            <Select
                              options={variantOptions}
                              value={chosenVariant}
                              onChange={handleVariantChange}
                              isOptionDisabled={option => !option.available}
                            />
                          </>
                        )}
                      </div>

                      <div className="s-bottom--lrg">
                        <p className="t-tiny t-upperext o-60 s-bottom--sml">
                          Price
                        </p>
                        <p className="t-delta">${priceCheck()}</p>
                      </div>

                      <button
                        className="btn btn--primary btn--med s-bottom--xlrg"
                        data-id={idCheck()}
                        onClick={addToCart}
                      >
                        Add to cart
                      </button>

                      <p className="t-epsilon">{item?.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </section>
    </>
  );

  async function addToCart(event: React.MouseEvent<HTMLButtonElement>) {
    const { id } = event.currentTarget.dataset;
    if (!id) return;

    dispatch(CartThunks.addItemToCart(id));
  }
};
