import { CartActions } from './actions';
import { CartTypes } from './types';

export interface CartState {
  isChanging: boolean;
  isOpen: boolean;
  client?: ShopifyBuy.Client;
  checkout?: ShopifyBuy.Cart;
}

const INITIAL_STATE: CartState = {
  isChanging: false,
  isOpen: false,
};

export default (state: CartState = INITIAL_STATE, action: CartActions) => {
  switch (action.type) {
    case CartTypes.isChanging:
      return {
        ...state,
        isChanging: true,
      };

    case CartTypes.Open:
      return {
        ...state,
        isOpen: true,
      };

    case CartTypes.Close:
      return {
        ...state,
        isOpen: false,
      };

    case CartTypes.CreateClient:
      return {
        ...state,
        client: action.payload.client,
      };

    case CartTypes.CreateCheckout:
      return {
        ...state,
        checkout: action.payload.checkout,
        isChanging: false,
      };

    default:
      return state || INITIAL_STATE;
  }
};
