import React, { useState } from 'react';
import { motion } from 'framer-motion';

import { CanvasZoomAnimation } from 'main/components/CanvasZoomAnimation';

export const RaceZoomAnimation: React.FC = () => {
  const [currentZoomImageIndex, setCurrentZoomImageIndex] = useState<number>();
  const isZoomAnimationCopy =
    currentZoomImageIndex && currentZoomImageIndex >= 120;

  return (
    <CanvasZoomAnimation
      imgPath="fpvonerace/zoom-animation/Prototyp_Zoom_White_"
      frameCount={145}
      imgWidth={1280}
      imgHeight={720}
      setCurrentImageIndex={setCurrentZoomImageIndex}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: isZoomAnimationCopy ? 1 : 0,
        }}
        transition={{ ease: 'easeInOut', duration: 0.2 }}
        className="fpvpilot__information--sticky"
      >
        <div className="fpvpilot__information--content">
          <motion.h2
            initial={{ opacity: 0, y: '20px' }}
            animate={{
              opacity: isZoomAnimationCopy ? '1' : '0',
              y: isZoomAnimationCopy ? '0' : '20px',
            }}
            transition={{
              ease: 'easeInOut',
              duration: 0.3,
            }}
            className="fpvpilot__information--title"
          >
            Cleaner, faster, more intuitive User Experience.
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: '20px' }}
            animate={{
              opacity: isZoomAnimationCopy ? '1' : '0',
              y: isZoomAnimationCopy ? '0' : '20px',
            }}
            transition={{
              ease: 'easeInOut',
              duration: 0.3,
              delay: 0.2,
            }}
            className="fpvpilot__information--description"
          >
            Access features and functions of your drone, camera, goggles or
            radio link from the new Orqa menus.
          </motion.p>
        </div>
      </motion.div>
    </CanvasZoomAnimation>
  );
};
