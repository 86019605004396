import { useMemo } from 'react';
import { useCookies } from 'react-cookie';

export const useUpdateModalCookie = () => {
  const [cookies, setCookie, removeCookie] = useCookies<
    'updateModal',
    { updateModal?: string }
  >(['updateModal']);

  function onAccept() {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 5);

    setCookie('updateModal', true, {
      expires,
    });
  }

  function removeUpdateCookie() {
    removeCookie('updateModal');
  }

  const value = useMemo(() => cookies.updateModal, [cookies]);

  return {
    value,
    onAccept,
    removeUpdateCookie,
  };
};
