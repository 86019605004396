import React, { Dispatch, SetStateAction, useState } from 'react';

export interface PageNumberContextProps {
  value?: number;
  dispatch: Dispatch<SetStateAction<number>>;
}

export const BlogPageNumberContext = React.createContext(
  {} as PageNumberContextProps,
);

export const BlogContextProvider: React.FC = ({ children }) => {
  const [blogPageNumber, setBlogPageNumber] = useState(1);

  return (
    <BlogPageNumberContext.Provider
      value={{ value: blogPageNumber, dispatch: setBlogPageNumber }}
    >
      {children}
    </BlogPageNumberContext.Provider>
  );
};
