import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { currentFrame } from 'main/helpers/getCurrentFrame';
import { preloadImages } from 'main/helpers/preloadImages';
import { scrollZoomListener } from 'main/helpers';

export const CanvasZoomAnimation: React.FC<CanvasScrollProps> = ({
  frameCount,
  imgPath,
  imgWidth,
  imgHeight,
  setCurrentImageIndex,
  children,
}) => {
  const { pathname } = useLocation();
  const canvasWrapperRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvasWrapper = canvasWrapperRef.current;
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');
    const isFpvRace = pathname === '/fpvonerace';
    const isFpvPilot = pathname === '/fpvonepilot';

    if (!canvas || !canvasWrapper || !context || (isFpvRace && isFpvPilot))
      return;

    const images: Array<HTMLImageElement> = [];
    preloadImages(images, frameCount, imgPath);
    const img = new Image();

    img.src = currentFrame(1, imgPath);
    canvas.width = imgWidth;
    canvas.height = imgHeight;

    img.onload = () => {
      context.drawImage(img, 0, 0);
    };

    window.addEventListener('scroll', () =>
      scrollZoomListener(
        canvas,
        context,
        canvasWrapper,
        frameCount,
        images,
        setCurrentImageIndex,
      ),
    );

    return () =>
      window.removeEventListener('scroll', () =>
        scrollZoomListener(
          canvas,
          context,
          canvasWrapper,
          frameCount,
          images,
          setCurrentImageIndex,
        ),
      );
  }, [
    frameCount,
    imgHeight,
    imgWidth,
    imgPath,
    pathname,
    setCurrentImageIndex,
  ]);

  return (
    <div ref={canvasWrapperRef} className="canvas__wrapper--secondary">
      {children}
      <canvas ref={canvasRef} className="canvas__zoom" />
    </div>
  );
};
