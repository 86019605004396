import * as React from 'react';

import { SEO } from 'main/components';

interface State {
  loading: boolean;
  isMobile: boolean;
}

export class Jobs extends React.Component<{}, State> {
  render() {
    return (
      <>
        <SEO seoTitle="ORQA - Jobs" />
        <section className="openpositions">
          <section className="splash">
            <div className="splash__content">
              <div className="wrapper wrapper--narrow">
                <h1 className="t-zeta t-upperext o-40 s-bottom--sml">Jobs</h1>
                <p className="t-beta t-primary s-bottom--med">Join our team</p>
              </div>
            </div>
          </section>
          <div className="wrapper wrapper--ultranarrow">
            <h3 className="t-gamma t-primary s-bottom--lrg">
              <a
                href="https://bit.ly/jOrqajobsJMCSE"
                target="_blank"
                rel="noopener noreferrer"
              >
                Junior Mobile Communications System Engineer
              </a>
            </h3>
            <p className="t-delta s-bottom--xlrg">
              We are looking to hire a 5th-year student, ideally just graduated
              who enjoys facing difficult challenges. Orqa is working on a
              custom 5G/4G software stack, enabling low latency AR/VR and
              drone applications. We are developing gNB/eNB and network core
              solutions for private mobile networks built for special
              applications where form-factor and latency performance are
              critical. You will be a part of the team that works on the
              bleeding edge mobile technology.
            </p>
          </div>
          <div className="wrapper wrapper--ultranarrow">
            <h3 className="t-gamma t-primary s-bottom--lrg">
              Embedded Software Engineer
            </h3>
            <p className="t-delta s-bottom--xlrg">
              We are looking to hire a skilled Embedded Software Engineer to
              join our software design team. As an Embedded Software Engineer,
              you will be responsible for executing complete embedded software
              life cycles for company and client hardware.
            </p>
          </div>
          <div className="wrapper wrapper--ultranarrow">
            <p className="t-delta s-bottom--xlrg">
              We are looking for a person with notable problem solving skills,
              flexibility, and willingness to continually tackle on complex and
              difficult challenges.
            </p>
            <p className="t-epsilon s-bottom--sml">
              If you wish to be a part of a dynamic and creative working
              environment, Orqa's diverse team is definitely right for you!
            </p>
            <p className="t-epsilon">
              Contact us at{' '}
              <a href="mailto:info@orqafpv.com">jobs@orqafpv.com</a> and apply
              for a job at Orqa!
            </p>
          </div>
        </section>
      </>
    );
  }
}
