import { motion } from 'framer-motion';
import React from 'react';
import InView from 'react-intersection-observer';

import { FPVSpecificationsItem } from 'main/components';

export const TechnicalSpecifications: React.FC = () => (
  <InView triggerOnce>
    {({ ref, inView }) => (
      <section className="fpvonerace--specifications">
        <motion.h3
          initial={{ opacity: 0, y: '20px' }}
          animate={{
            opacity: inView ? 1 : 0,
            y: inView ? 0 : '20px',
          }}
          transition={{
            ease: 'easeOut',
            duration: 0.4,
          }}
          className="fpvpilot__splitsection__title--secondary t-upper"
        >
          Technical specifications
        </motion.h3>
        <motion.h2
          ref={ref}
          initial={{ opacity: 0, y: '30px' }}
          animate={{
            opacity: inView ? 1 : 0,
            y: inView ? 0 : '30px',
          }}
          transition={{
            ease: 'easeOut',
            delay: 0.2,
            duration: 0.4,
          }}
          className="fpvonerace__specifications__title--primary t-upper"
        >
          compare the specs.
        </motion.h2>

        <div className="fpvonerace__specifications--wrapper">
          <div className="fpvonerace__specifications--collumn fpvonerace__specifications__collumn--primary">
            <FPVSpecificationsItem
              title="Viewing angle"
              description="33° (race mode)"
              inView={inView}
              titleDelay={0.4}
              descriptionDelay={0.5}
            />
            <FPVSpecificationsItem
              title="Screen resolution"
              description="1024 x 768 pixels"
              inView={inView}
              titleDelay={0.6}
              descriptionDelay={0.7}
            />
            <FPVSpecificationsItem
              title="Aspect ratio"
              description="4:3 Native"
              inView={inView}
              titleDelay={0.7}
              descriptionDelay={0.8}
            />
            <FPVSpecificationsItem
              title="Power supply"
              description="6-26VDC (2-6S Li-ion Battery)"
              inView={inView}
              titleDelay={0.8}
              descriptionDelay={0.9}
            />
            <FPVSpecificationsItem
              title="Power consumption"
              description="2.6W, typical"
              inView={inView}
              titleDelay={0.9}
              descriptionDelay={1}
            />
            <FPVSpecificationsItem
              title="Integrated Head Tracker output"
              inView={inView}
              description="3.5mm jack"
              titleDelay={1.1}
              descriptionDelay={1.2}
            />
            <FPVSpecificationsItem
              title="HDMI video input"
              description="micro HDMI"
              inView={inView}
              titleDelay={1.2}
              descriptionDelay={1.3}
            />
            <FPVSpecificationsItem
              title="Battery connector"
              description="2.1 x 5.5mm barrel connector"
              inView={inView}
              titleDelay={1.3}
              descriptionDelay={1.4}
            />
          </div>
          <div className="fpvonerace__specifications--collumn">
            <FPVSpecificationsItem
              title="IPD range"
              description="56-74 mm"
              inView={inView}
              titleDelay={1.5}
              descriptionDelay={1.6}
            />
            <FPVSpecificationsItem
              title="Focus adjust"
              description="-4D - +4D"
              inView={inView}
              titleDelay={1.6}
              descriptionDelay={1.7}
            />
            <FPVSpecificationsItem
              title="Integrated DVR"
              description="1280x960, 50/60fps, H.264, mp4"
              inView={inView}
              titleDelay={1.7}
              descriptionDelay={1.8}
            />
            <FPVSpecificationsItem
              title="Standard receiver bay"
              description="5V, High power"
              inView={inView}
              titleDelay={1.8}
              descriptionDelay={1.9}
            />
            <FPVSpecificationsItem
              title="Secondary bay for additional modules"
              description="5V, SDIO, Uart"
              inView={inView}
              titleDelay={1.9}
              descriptionDelay={2}
            />
            <FPVSpecificationsItem
              title="Weight (without battery)"
              description="256g"
              inView={inView}
              titleDelay={2}
              descriptionDelay={2.1}
            />
            <FPVSpecificationsItem
              title="Dimensions"
              description="177 x 107 x 72mm"
              inView={inView}
              titleDelay={2.1}
              descriptionDelay={2.2}
            />
          </div>
        </div>
      </section>
    )}
  </InView>
);
