import React, { useEffect, useState } from 'react';

export const ProgressBar: React.FC = () => {
  const [scrolledProgress, setScrolledProgress] = useState<string>('0%');

  const scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${(scrollPx / winHeightPx) * 100}%`;

    setScrolledProgress(scrolled);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollProgress);
    return () => window.removeEventListener('scroll', scrollProgress);
  }, []);

  return (
    <div className="fpvpilot__progress--wrapper">
      <div
        style={{
          height: '1px',
          backgroundColor: 'white',
          width: scrolledProgress,
        }}
        className="fpvpilot__progress--bar"
      />
    </div>
  );
};
