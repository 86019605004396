import React from 'react';
import { Helmet } from 'react-helmet-async';

export interface SEOProps {
  seoTitle?: string;
  seoDescription?: string;
  imagePath?: string;
}

const seoDefaults = {
  title: 'ORQA - Never stop flying.',
  descritpion:
    'Orqa d.o.o. was founded in 2018. It is a company whose mission is to become the World’s number one technology provider for First Person View (FPV) and advanced Remote Reality (RR) applications by developing enabling technology for next-generation vision systems.',
};

export const SEO: React.FC<SEOProps> = ({
  seoTitle = seoDefaults.title,
  seoDescription = seoDefaults.descritpion,
  imagePath,
}) => (
  <Helmet>
    <title>{seoTitle}</title>
    <meta charSet="utf-8" />
    <link rel="canonical" href={window.location.href} />
    <meta name="description" content={seoDescription} />​
    {/* Open Graph metadata */}
    <meta property="og:title" content={seoTitle} />
    <meta property="og:description" content={seoDescription} />
    <meta property="og:url" content={window.location.href} />
    <meta
      property="og:image"
      content={imagePath || 'https://orqafpv.com/images/orqa-share.jpg'}
    />
    {/* Twitter metadata */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={seoTitle} />
    <meta name="twitter:description" content={seoDescription} />
    <meta name="twitter:url" content={window.location.href} />
    <meta
      name="twitter:image"
      content={imagePath || 'https://orqafpv.com/images/orqa-share.jpg'}
    />
  </Helmet>
);
