import * as React from 'react';
import { useQuery } from 'react-apollo';

import { GET_DRIVERS, GET_FIRMWARE } from 'main/graphql';
import { LoadingSpinner } from 'main/components';
import { FirmwareList } from 'main/components/FirmwareList';

export const Downloads: React.FC = () => {
  const {
    data: driversData,
    loading: driversLoading,
    error: driversError,
  } = useQuery(GET_DRIVERS);
  const {
    loading: firmwareLoading,
    data: firmwareData,
    error: firmwareError,
  } = useQuery(GET_FIRMWARE);

  return (
    <section className="page">
      {driversLoading || firmwareLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="wrapper wrapper--narrow  s-bottom--xlrg">
            <h1 className="t-beta">Firmware</h1>
            <div className="s-top--xlrg">
              <>
                {firmwareError && <p>Error...</p>}
                {firmwareData &&
                  firmwareData.allFirmwares &&
                  firmwareData.allFirmwares.edges.length !== 0 && (
                    <FirmwareList
                      listType="firmware"
                      edges={firmwareData.allFirmwares.edges}
                    />
                  )}
              </>
            </div>
          </div>
          <div className="wrapper wrapper--narrow">
            <h1 className="t-beta">Drivers</h1>
            <div className="s-top--xlrg">
              <>
                {driversError && <p>Error...</p>}
                {driversData && driversData.allDriverss && (
                  <FirmwareList
                    listType="driver"
                    edges={driversData.allDriverss.edges}
                  />
                )}
              </>
            </div>
          </div>
        </>
      )}
    </section>
  );
};
