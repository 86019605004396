export function updateCanvasZoomImages(
  index: number,
  images: HTMLImageElement[],
  context: CanvasRenderingContext2D | null,
  canvas: HTMLCanvasElement | null,
) {
  if (!images[index] || !context || !canvas) return;

  context.drawImage(images[index], 0, 0);
}
