import * as React from 'react';
import { useQuery } from 'react-apollo';

import { LoadingSpinner } from 'main/components';
import { GET_MANUALS } from 'main/graphql';

// todo - handle error case
export const Manual: React.FC = () => {
  const { loading, data, error } = useQuery(GET_MANUALS);

  return (
    <section className="page">
      <div className="wrapper wrapper--narrow">
        <h1 className="t-beta s-bottom--med">Manuals</h1>
        <div className="page__content">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              {error && <p>Error...</p>}
              {data && data.allManuals && data.allManuals.edges.length !== 0 && (
                <div className="page__table">
                  {data.allManuals.edges.map((manual: any, index: number) => {
                    return (
                      <div key={index} className="page__table__item">
                        <div className="page__table__item__heading">
                          <p className="t-eta">{manual.node.title}</p>
                          <p className="t-zeta o-40">
                            {manual.node.manual_version}
                          </p>
                        </div>
                        <div className="page__table__item__action">
                          <a
                            href={manual.node.link_to_manual.url}
                            className="btn btn--sml btn--nopadding"
                          >
                            Download
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};
