import React from 'react';
import { motion } from 'framer-motion';
import InView from 'react-intersection-observer';

import { LazyImage } from 'main/components';

export const NeilMarekSpotlight: React.FC = () => (
  <InView triggerOnce={false}>
    {({ ref, inView }) => (
      <div className="fpvrace--testimonial fpvrace__testimonial--tertiary">
        <LazyImage
          src={require('main/assets/images/fpvonerace/neil-marek-testimonial.webp')}
          alt="Race Niel Marek spotlight"
          className="fpvpilot__spotlight--mobile__img--secondary"
        />
        <div ref={ref} className="fpvrace__spotlight--wrapper">
          <div className="fpvrace__spotlight--content">
            <motion.h2
              ref={ref}
              initial={{ opacity: 0, y: '20px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '20px' }}
              transition={{ ease: 'circOut', duration: 0.3 }}
              className="fpvpilot__spotlight--title"
            >
              Beautiful, inside and out.
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '40px' }}
              transition={{ ease: 'circOut', duration: 0.3, delay: 0.3 }}
              className="fpvpilot__spotlight--description"
            >
              “These goggles are so nice. From the displays in the inside to the
              fit and finish of the shell, they look amazing. The super smooth
              60 fps DVR and the FPV connect module make these goggles a
              top-shelf option for any pilot looking to run the best possible
              gear."
            </motion.p>
            <motion.hr
              initial={{ width: 0 }}
              animate={{
                width: inView ? '100%' : '0',
              }}
              transition={{ ease: 'easeOut', duration: 0.3, delay: 0.3 }}
              className="fpvpilot__spotlight--divider"
            />

            <div className="fpvpilot__testimonial--wrapper">
              <motion.div
                initial={{ opacity: 0, y: '40px' }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : '40px',
                }}
                transition={{ ease: 'circOut', duration: 0.3, delay: 0.6 }}
                className="fpvonerace__testimonial--content"
              >
                <LazyImage
                  src={require('main/assets/images/fpvonerace/niel-marek.webp')}
                  alt="Niel Marek"
                  className="fpvpilot__testimonial--image"
                />
                <h3 className="fpvpilot__testimonial--description">
                  Niel Marek
                </h3>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    )}
  </InView>
);
