import React, { useState, useEffect, useRef, useContext } from 'react';
import { useQuery } from 'react-apollo';
import { useParams, Link } from 'react-router-dom';

import { GET_BLOG_POSTS } from 'main/graphql';
import { BlogPostCard, BlogItem, BlogPageNumberContext } from 'modules';
import { LoadingSpinner, SEO } from 'main/components';
import { usePagination } from 'main/hooks/usePagination';

interface Params {
  filter?: string;
}

type Props = Params;

export const Blog: React.FC<Props> = () => {
  const didMountRef = useRef(false);
  const pageNumberContext = useContext(BlogPageNumberContext);
  const { filter } = useParams<Params>();
  const { loading, data, error, fetchMore } = useQuery(GET_BLOG_POSTS);
  const [allBlogPosts, setAllBlogPosts] = useState<Array<BlogItem>>([]);
  const [categories] = useState<Set<string>>(new Set());
  const [selectedBlogPosts, setSelectedBlogPosts] = useState<Array<BlogItem>>(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);
  const [pagedItems, Pagination] = usePagination(
    selectedBlogPosts,
    20,
    undefined,
    pageNumberContext,
  );
  useEffect(onMount, [data]);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }
    if (!filter) {
      pageNumberContext.dispatch(1);
      setSelectedBlogPosts(allBlogPosts);
      return;
    }
    pageNumberContext.dispatch(1);
    setSelectedBlogPosts(allBlogPosts.filter(post => post.category === filter));
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <section className="blog">
      <SEO
        seoTitle="ORQA - Blog"
        seoDescription="ORQA blog. Latest news and announcements."
      />

      <header className="blog__heading">
        <div className="wrapper wrapper--wide">
          <h1 className="t-beta">Blog</h1>
        </div>
      </header>
      <aside className="blog__categories">
        <nav className="blog__sidenav">
          <p className="t-secondary t-upperext t-delta">Categories</p>

          <ul>
            <Link to="/blog/">Show all</Link>
            {Array.from(categories).map((category: string, index: number) => (
              <li key={index}>
                <Link to={category}>
                  {category?.charAt(0).toUpperCase() + category.slice(1)}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </aside>

      <section className="blog__layout">
        {loading || isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {error && (
              <section className="emptystate emptystate--nocontent t-negative">
                <div className="wrapper wrapper--narrow">
                  <header className="emptystate__heading">
                    <h2 className="t-gamma">Oops, there is something wrong.</h2>
                  </header>
                  <div className="emptystate__content">
                    <p className="t-epsilon">
                      We cannot show you the requested content now. Fly back to
                      homepage and discover more about ORQA.
                    </p>
                  </div>
                  <footer className="emptystate__footer">
                    <Link to="/fpvone" className="btn btn--primary btn--med">
                      Fly back home
                    </Link>
                  </footer>
                </div>
              </section>
            )}
            {selectedBlogPosts && selectedBlogPosts.length !== 0 && (
              <>
                <div className="wrapper wrapper--wide">
                  <div className="blog__posts">
                    {selectedBlogPosts
                      .filter(post => post.featuredPost === true)
                      .map(post => (
                        <article
                          key={post.slug}
                          className="blog__posts__post blog__posts__post--featured"
                        >
                          <div className="blog__posts__label">
                            ORQA featured
                          </div>
                          <BlogPostCard key={post.slug} post={post} />
                        </article>
                      ))}
                    {pagedItems
                      .filter(post => post.featuredPost === false)
                      .map((post: BlogItem, index: number) => (
                        <article key={index} className="blog__posts__post">
                          <BlogPostCard post={post} />
                        </article>
                      ))}
                  </div>
                  <div className="blog__pagination">
                    <Pagination />
                  </div>
                </div>
              </>
            )}
            {!selectedBlogPosts.length && !error && (
              <p className="blog__error">No blog posts yet...</p>
            )}
          </>
        )}
      </section>
    </section>
  );

  function onMount() {
    if (!loading) {
      destructureBlogPosts(data, []);
    }
  }

  function destructureBlogPosts(data: any, posts: BlogItem[]) {
    setIsLoading(true);
    if (!data || !data.allBlog_posts || data.allBlog_posts.edges.length === 0) {
      setIsLoading(false);
      return;
    }

    data.allBlog_posts.edges.forEach((post: any) => {
      const blogItem: BlogItem = {
        title: post.node?.title[0].text,
        shortContent: post.node?.short_content,
        longContent: post.node?.long_content,
        author: post.node?.author,
        publishedDate: post.node?.published_date,
        slug: post.node._meta.uid,
        category: post.node.category?.category,
        heroImage: post.node.hero_image?.url,
        featuredPost: post.node.featured_post,
      };
      posts.push(blogItem);
    });

    if (data.allBlog_posts.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          after: data.allBlog_posts.pageInfo.endCursor,
        },
        updateQuery: (prev: any, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            console.log(prev);
            return;
          }
          destructureBlogPosts(fetchMoreResult, posts);
        },
      });
      return;
    }

    setAllBlogPosts(posts);

    // Get available blog categories
    posts.forEach(post => {
      categories?.add(post.category);
    });

    if (!filter) {
      setSelectedBlogPosts(posts);
      setIsLoading(false);
      return;
    }
    setSelectedBlogPosts(posts.filter(post => post.category === filter));
    setIsLoading(false);
  }
};
