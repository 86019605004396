import React from 'react';

interface Props {
  icon: string;
  link: string;
}

export const PilotItemSocial: React.FC<Props> = ({ icon, link }) => {
  const icons = {
    fb: require('main/assets/images/icon-social-facebook.svg'),
    ig: require('main/assets/images/icon-social-instagram.svg'),
    tikTok: require('main/assets/images/icon-social-tiktok.svg'),
    yt: require('main/assets/images/icon-social-youtube.svg'),
  };

  return (
    <a
      key={icon}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="pilot-item__socials-link"
    >
      <img src={icons[icon]} alt="Social icon" className="header__icon" />
    </a>
  );
};
