import React from 'react';

import {
  BuildExperience,
  CTA,
  FPVVideo,
  Hero,
  Measurements,
  PilotCarousel,
  PilotNewsletter,
  PilotZoomAnimation,
  PowerSpecifications,
  ProductPricing,
  ProductQuality,
  TechnicalSpecifications,
  Technology,
  VisualExperience,
} from 'modules/fpv-one-pilot';
import { SEO } from 'main/components';

export const FPVOnePilot: React.FC = () => (
  <>
    <SEO seoTitle="ORQA - FPV.One Pilot" />
    <section className="fpvpilot">
      <Hero />
      <ProductQuality />
      <BuildExperience />
      <Technology />
      <VisualExperience />
      <PowerSpecifications />
      <Measurements />
      <TechnicalSpecifications />
      <CTA />
      <FPVVideo />
      <PilotZoomAnimation />
      <PilotCarousel />
      <ProductPricing />
      <PilotNewsletter />
    </section>
  </>
);
