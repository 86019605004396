import React from 'react';
import { motion } from 'framer-motion';
import InView from 'react-intersection-observer';

import { LazyImage } from 'main/components';

export const EvanTurnerSpotlight: React.FC = () => (
  <InView triggerOnce={false}>
    {({ ref, inView }) => (
      <div className="fpvrace__spotlight--root">
        <LazyImage
          src={require('main/assets/images/fpvonerace/fpvrace-spotlight.webp')}
          alt="Pilot spotlight"
          className="fpvpilot__spotlight__mobile--img"
        />
        <div ref={ref} className="fpvrace__spotlight--wrapper">
          <div className="fpvrace__testimonial--quinary">
            <motion.h2
              id="spotlight"
              ref={ref}
              initial={{ opacity: 0, y: '20px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '20px' }}
              transition={{ ease: 'circOut', duration: 0.3 }}
              className="fpvpilot__spotlight--title"
            >
              A tried and true performance goggle.
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '40px' }}
              transition={{ ease: 'circOut', duration: 0.3, delay: 0.3 }}
              className="fpvpilot__spotlight--description"
            >
              “It is not made for a large pleasant viewing experience, but
              instead provides the amenities needed to perform when it counts.
              Racing, freestyle, it doesn’t matter, if you are able to see the
              entire image at once without having to move or refocus your eyes I
              truly believe you have the ability to perform better.
              Additionally, it's imperative for this image to be crisp. When
              scaling down an image on a larger fov goggle you lose a
              significant amount of pixel density, the only way to fix this is
              to bring a full resolution race field of view goggle to market.
              Which is what we did. Combine that with the 485 dollar price point
              and you will not find a better bang for your buck goggle on the
              market.”
            </motion.p>
            <motion.hr
              initial={{ width: 0 }}
              animate={{
                width: inView ? '100%' : '0',
              }}
              transition={{ ease: 'easeInOut', duration: 0.2 }}
              className="fpvpilot__spotlight--divider"
            />

            <div className="fpvpilot__testimonial--wrapper">
              <motion.div
                initial={{ opacity: 0, y: '40px' }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : '40px',
                }}
                transition={{ ease: 'circOut', duration: 0.3, delay: 0.6 }}
                className="fpvpilot__testimonial--content"
              >
                <LazyImage
                  src={require('main/assets/images/fpvonerace/evan-turner.webp')}
                  alt="Lexie Janson"
                  className="fpvpilot__testimonial--image"
                />
                <div>
                  <h3 className="fpvpilot__testimonial--description">
                    HeadsupFPV
                  </h3>
                  <p className="fpvpilot__testimonial--title">Evan Turner</p>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    )}
  </InView>
);
