import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { CookiesProvider } from 'react-cookie';

import { configureStore } from 'modules/redux-store';
import { client } from 'modules/prismic';
import { BlogContextProvider } from 'modules';
import { Layout, ScrollToTop, Footer } from 'main/components';

import { routes } from '../constants';

export const Routing = () => {
  const store = configureStore();

  return (
    <Provider store={store}>
      <BlogContextProvider>
        <ApolloProvider client={client}>
          <HelmetProvider>
            <BrowserRouter>
              <ScrollToTop>
                <CookiesProvider>
                  <Layout>
                    {routes.map(({ path, Component }) => (
                      <Route key={path} exact path={path}>
                        {({ match }) => (
                          <CSSTransition
                            in={match != null}
                            timeout={2000}
                            classNames="page-container"
                            unmountOnExit
                          >
                            <div className="page-container">
                              <Component />
                              <Footer />
                            </div>
                          </CSSTransition>
                        )}
                      </Route>
                    ))}
                  </Layout>
                </CookiesProvider>
              </ScrollToTop>
            </BrowserRouter>
          </HelmetProvider>
        </ApolloProvider>
      </BlogContextProvider>
    </Provider>
  );
};

export default withRouter(Routing);
