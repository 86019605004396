import React from 'react';
import Slider, { Settings } from 'react-slick';

import { LazyImage } from 'main/components';

export const PilotCarousel: React.FC = () => {
  const settings: Settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    appendDots: dots => (
      <>
        <div className="fpvpilot__slider--dots">
          <ul className="slick-dots">{dots}</ul>
        </div>
      </>
    ),
  };

  return (
    <div className="fpvpilot__slider--wrapper">
      <Slider {...settings}>
        <div className="fpvpilot__spotlight--root">
          <div className="fpvpilot__spotlight__root--wrapper">
            <LazyImage
              src={require('main/assets/images/fpvonepilot/min-chan-kim-spotlight-mobile.webp')}
              alt="Pilot spotlight"
              className="fpvpilot__spotlight--img"
            />
          </div>
          <div className="fpvpilot__spotlight--wrapper">
            <div className="fpvpilot__spotlight--content">
              <h2 className="fpvpilot__spotlight--title">
                Do more, see better, and perform at your highest.
              </h2>
              <p className="fpvpilot__spotlight--description">
                "When I put my goggles on that means the game has officially
                begun. All I truly care about is the overall performance of my
                gear and how comfortable it is to use. With that in mind, the
                new Orqas are, without a doubt, the way to go."
              </p>
              <hr className="fpvpilot__spotlight--divider" />

              <div className="fpvpilot__testimonial--wrapper">
                <div className="fpvpilot__testimonial--content">
                  <LazyImage
                    src={require('main/assets/images/fpvonepilot/min-chan-kim.webp')}
                    alt="Lexie Janson"
                    className="fpvpilot__testimonial--image"
                  />
                  <div>
                    <h3 className="fpvpilot__testimonial--description">
                      MCKFPV
                    </h3>
                    <p className="fpvpilot__testimonial--title">MinChan Kim</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="fpvpilot__spotlight__root--secondary">
          <div className="fpvpilot__spotlight__root--wrapper">
            <LazyImage
              src={require('main/assets/images/fpvonepilot/mai-shiro-spotlight-mobile.webp')}
              alt="Pilot spotlight"
              className="fpvpilot__spotlight--img"
            />
          </div>
          <div className="fpvpilot__spotlight--wrapper">
            <div className="fpvpilot__spotlight--content">
              <h2 className="fpvpilot__spotlight--title">
                Settle for nothing less.
              </h2>
              <p className="fpvpilot__spotlight--description">
                "Absolutely in love with the Orqa goggles!" The view is very
                clean all the time and the UI is quite pleasant to the eye. Most
                importantly, it's easy to check and send my DVR to my phone,
                which is very helpful when sharing my flight experience with the
                FPV community."
              </p>
              <hr className="fpvpilot__spotlight--divider" />

              <div className="fpvpilot__testimonial--wrapper">
                <div className="fpvpilot__testimonial--content">
                  <LazyImage
                    src={require('main/assets/images/fpvonepilot/mai-shiro.webp')}
                    alt="Lexie Janson"
                    className="fpvpilot__testimonial--image"
                  />
                  <div>
                    <h3 className="fpvpilot__testimonial--description">
                      NAMAIIKIFPV
                    </h3>
                    <p className="fpvpilot__testimonial--title">Mai Shiro</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};
